import React, { useEffect, useRef, useState } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import classnames from 'classnames';
import { get, kebabCase } from 'lodash';
import moment from 'moment';
import AsyncButton from 'react-async-button';
import { Link } from 'react-router-dom';

import { firebaseConfig } from '../../../../constants.js';

import { usePrevious } from '../../../../components/Navigation';
import Spinner from '../../../../components/Spinner';

import { Button, Checkbox, IconButton, LinearProgress } from '@material-ui/core';

import './styles.scss';

const firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] :  firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

export default function ItemShop(props) {
  const { breakpoint, isBlackout, pendingMisc, promptLogin, refreshUser, user, t } = props;

  const [currentItems, setCurrentItems] = useState();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [lastUpdated, setLastUpdated] = useState();
  const [selectedShopItem, setSelectedShopItem] = useState();
  const [shopPending, setShopPending] = useState(true);
  const [shopExpanded, setShopExpanded] = useState();
  const [wishlistButtonLabel, setWishlistButtonLabel] = useState(user?.userPrefs?.hasWishlist ? t('view_your_wishlist') : t('create_a_wishlist'));

  const prevUser = usePrevious(user);

  const itemShopRef = useRef(null);

  const isMobile = breakpoint === 'mobile';

  function expandShop() {
    setShopExpanded(!shopExpanded);
    itemShopRef && itemShopRef.current.scrollTo && itemShopRef.current.scrollTo(0, 0);
  }

  function updateSelectedShopItem(event, item) {
    event.stopPropagation();

    setSelectedShopItem(item);
  }

  function toggleWishlist(item) {

    if (!user || !user.id) return promptLogin('from wishlist');

    const wishlist = get(user, 'userPrefs.wishlist', []);
    const itemIndex = wishlist.findIndex(wItem => wItem.id === item.id);
    const isWishlisted = itemIndex >= 0;

    if (isWishlisted) wishlist.splice(itemIndex, 1);
    else wishlist.push(item);

    return db.collection('userPrefs').doc(user.id).set({
      hasWishlist: Boolean(wishlist.length),
      wishlist
    }, { merge: true }).then(() => {
      refreshUser();
    }).catch(error => console.error(error));
  }

  useEffect(() => {
    const fetchItemShop = () => {
      setShopPending(true);

      db.collection('item-shop').doc('aaa-meta').get().then(resultsRaw => {
        const { current_items, last_updated } = resultsRaw.data();

        current_items.forEach(item => item.id = kebabCase(item.name));

        setLastUpdated(moment.unix(Date.parse(last_updated.toDate()) / 1000).format('LLL'));
        setCurrentItems(current_items);
        setShopPending(false);
      });
    }

    if (!pendingMisc && !isBlackout && !currentItems) fetchItemShop();
  }, [pendingMisc, isBlackout, currentItems]);

  useEffect(() => {
    if (user?.userPrefs?.hasWishlist !== prevUser?.userPrefs?.hasWishlist) setWishlistButtonLabel(user?.userPrefs?.hasWishlist ? t('view_your_wishlist') : t('create_a_wishlist'))
  }, [user, prevUser, t]);

  return (
    <div ref={itemShopRef} className={classnames('item-shop-container', {
      'expanded': shopExpanded,
      'loading': pendingMisc || (shopPending && !isBlackout)
     })}>
      {isMobile &&
        <>
          {user.id
            ? <Link className="view-button view-wishlist" to={'/account'}>{wishlistButtonLabel}</Link>
            : <Button className="view-button view-wishlist" onClick={() => promptLogin('from item shop')}>
                {wishlistButtonLabel}
              </Button>
          }
        </>
      }

      {((shopPending && !isBlackout) || pendingMisc) && <LinearProgress />}

      <ul className={classnames('shop-list', { 'show': !shopPending && !isBlackout })}>
        {currentItems && currentItems.map((item, i) => {
          const wishlist = get(user, 'userPrefs.wishlist', []);
          const isWishlisted = wishlist.some(wItem => wItem.id === item.id);
          const isRightSide = (i%8) >= 5;

          return (
            <li key={`${item.manifestId}-${item.name}`} onClick={(event) => updateSelectedShopItem(event, item)} className={classnames('shop-item-container', {
              'selection': selectedShopItem,
              'wishlisted': isWishlisted
            })}>
              <div className={classnames('shop-modal', {
                'show': selectedShopItem
              })} onClick={(event) => updateSelectedShopItem(event)} />

              <div className={classnames(`shop-item ${item.rarity.toLowerCase()}`, {
                emote: item.category === 'emotes',
                open: get(selectedShopItem, 'id') === item.id,
                right: isRightSide,
              })}>
                <img alt={item.name} src={item.imageUrl} />

                <div className="item-info">
                  <AsyncButton
                    className="wishlist-button"
                    onClick={() => toggleWishlist(item)}
                    pendingText="Wishlist"
                    text="Wishlist"
                  >
                    {({buttonText, isPending}) => (
                      <>
                        {buttonText}

                        {isPending
                          ? <Spinner />
                          : <Checkbox
                            checked={isWishlisted}
                            value={isWishlisted}
                          />
                        }
                      </>
                    )}
                  </AsyncButton>

                  <div className="content">
                    <p className="item-name">{item.name}</p>
                    <p className="item-price"><img src="/assets/icons/vBuck.png" alt="vBuck icon" /> {item.vBucks}</p>
                  </div>
                    
                  <IconButton className="close-shop-item" onClick={(event) => updateSelectedShopItem(event)}>
                    <i className="material-icons">close</i>
                  </IconButton>
                </div>
              </div>
            </li>
          );
        })}
      </ul>

     {isBlackout &&
       <img className={classnames('blackhole', { 'show': !pendingMisc && imageLoaded })} src="https://i.imgur.com/ouM7SJL.png" onLoad={() => setImageLoaded(true)} alt="black hole" />
     }

      {isMobile && !isBlackout &&
        <span className="last-updated">
          {(!shopPending && !pendingMisc) &&
            `Item Shop Last updated: ${lastUpdated}`
          }
        </span>
      }

      {!isMobile &&
        <div className="view-buttons">
          {user.id
            ? <Link className="view-button view-wishlist" to={'/account'}>{wishlistButtonLabel}</Link>
            : <Button className="view-button view-wishlist" onClick={() => promptLogin('from item shop')}>
                {wishlistButtonLabel}
              </Button>
          }

          <span className="last-updated">
            {(shopPending || pendingMisc)
              ? 'Fetching Current Item Shop'
              : `Item Shop Last updated: ${lastUpdated}`
            }
          </span>
        </div>
      }

      {(isMobile && !isBlackout) &&
        <div onClick={expandShop} className={classnames('expand-shop', {
          expanded: shopExpanded,
        })}>
          {(shopPending || pendingMisc)
            ? 'Fetching Current Item Shop'
            : shopExpanded ? 'Collapse item shop' : 'See current item shop'
          }
          {(!shopPending && !pendingMisc) && <i className="material-icons">{shopExpanded ? 'expand_less' : 'expand_more'}</i>}
        </div>
      }
    </div>
  );
}