import React, { useEffect, useState } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { get } from 'lodash';

import { firebaseConfig } from '../../constants.js';
import { translations } from '../../i18n.js';

import Feature from './components/Feature';
import Gifters from './components/Gifters';
import ItemShop from './components/ItemShop';
import LoginPrompt from '../../components/LoginPrompt';

import './styles.scss';

const firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] :  firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

export default function Home(props) {
  const { history, openAccountCreation, user } = props;

  const [features, setFeatures] = useState([]);
  const [isBlackout, setIsBlackout] = useState(false);
  const [languageMap, setLanguageMap] = useState(translations[get(props.user, 'userPrefs.language', 'en')]);
  const [pendingMisc, setPendingMisc] = useState(true);
  const [showGifters, setShowGifters] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  function t(key, names) {
    let str = languageMap[key];

    for (const name in names) {
      str = str.replace(`{{${name}}}`, names[name])
    }

    return str;
  }

  const promptLogin = (label) => {
    window.analytics.track('Get login prompt', {
      category: 'homepage',
      label
    });

    setShowLoginPrompt(true);
    window.analytics.track('Prompted to log in');
  }

  useEffect(() => {
    setLanguageMap(translations[get(props.user, 'userPrefs.language', 'en')]);
  }, [props.user]);

  useEffect(() => {
    window.analytics.page('home');
    history.push(`/home`);

    async function getFeatures() {
      const newFeatures = [];
      const featuresRef = db.collection('featured');

      const misc = await db.collection('misc').get();

      if (misc && !misc.empty) {
        const blackoutData = misc.docs[0].data();
        const { isBlackout } = blackoutData;

        setIsBlackout(isBlackout);
        setPendingMisc(false);
      }

      const special = await featuresRef.doc('special').get();

      if (special && !special.empty) {
        const item = special.data();

        if (!item) return;

        const itemNumber = item.number;

        async function asyncForEach(array, callback) {
          for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
          }
        }

        asyncForEach(itemNumber, async (sp) => {
          const item =  await db.collection('specials').where('number', '==', sp).limit(1).get();

          newFeatures.push(item.docs[0].data());
          setFeatures([...newFeatures]);
        });
      };
    }

    getFeatures();
  }, [history]);

  return (
    <div className="homepage">
      <div className="view-container">
        {showLoginPrompt &&
          <LoginPrompt
            onClose={() => setShowLoginPrompt(false)}
            openAccountCreation={openAccountCreation}
          />
        }

        {showGifters &&
          <Gifters onClose={() => setShowGifters(false)} />
        }

        <ItemShop
          breakpoint={props.breakpoint}
          isBlackout={isBlackout}
          user={user}
          pendingMisc={pendingMisc}
          promptLogin={promptLogin}
          refreshUser={props.refreshUser}
          t={t}
        />

        {features.map((special, i) => (
          <Feature
            buttonText={special.buttonText}
            description={special.description}
            history={history}
            imgSrc={special.imageSrc}
            isExternal={special.isExternal}
            isLoading={typeof special.number === 'undefined'}
            key={`special-feature-${i}`}
            label={`Featured ${special.type}`}
            title={special.name}
            url={special.url}
          />
        ))}
      </div>
    </div>
  );
}
