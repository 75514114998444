import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { get, uniqBy } from 'lodash';

import { firebaseConfig } from '../../constants.js';
import { translations } from '../../i18n.js';
import { validateEmail } from '../../utils/regexes.js';

import { AppBar, Checkbox, Button, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Input, InputAdornment, InputLabel, Switch, Tab, Tabs, Tooltip } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Banner from '../../components/Banner';
import Badge from '../../components/Badge';
import Spinner from '../../components/Spinner';
import Wishlist from '../../components/Wishlist';
import WishlistBuilder from '../../components/WishlistBuilder';

import './styles.scss';

const firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] :  firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

export default function Account(props) {
  const { breakpoint, history, isBetaMember, isLoggedOut, isPaid, location, promptUpgrade, refreshUser,
    selectedTab = 0, updateBetaMembership, user } = props;

  const shareUrl = useRef(null);

  const isMobile = breakpoint === 'mobile';
  const badges = user.badges && uniqBy(user.badges, badge => badge.badgeKey);
  const displayName = get(user, 'userPrefs.displayName');
  const wishlist = get(user, 'userPrefs.wishlist', []);
  const wishlistId = get(user, 'userPrefs.wishlistId');

  const wishlistUrl = wishlistId && `https://fortnitedashboard.com/wishlist/${wishlistId}`;

  const [displayNameEdited, setDisplayNameEdited] = useState(false);
  const [editing, setEditing] = useState(false);
  const [emailEdited, setEmailEdited] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [initializingPublicWishlist, setInitializingPublicWishlist] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoadingWishlist, setIsLoadingWishlist] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [emailPrefs, setEmailPrefs] = useState(get(user, 'userPrefs.emailPrefs', { features: false, weeklies: false }));
  const [languageLoading, setLanguageLoading] = useState('');
  const [languageMap] = useState(translations[get(user, 'userPrefs.language', 'en')]);
  const [languages, setLanguages] = useState();
  const [logoutPending, setLogoutPending] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showWishlistBuilder, setShowWishlistBuilder] = useState(location.state?.openModalFor === 'edit-wishlist');
  const [success, setSuccess] = useState('');
  const [tab, setTab] = useState(selectedTab >= 0 ? selectedTab : 0);
  const [updatedDisplayName, setUpdatedDisplayName] = useState('');
  const [updatedEmail, setUpdatedEmail] = useState('');
  const [updatedCurrentPassword, setUpdatedCurrentPassword] = useState('');
  const [updatedNewPassword, setUpdatedNewPassword] = useState('');
  const [wishlistBuilderTab, setWishlistBuilderTab] = useState(false);

  function t(key, names) {
    let str = languageMap[key];

    for (const name in names) {
      str = str.replace(`{{${name}}}`, names[name])
    }

    return str;
  }

  function handleTabChange(event, newTab) {
    event.stopPropagation();

    window.analytics.track('Switched tabs',
      {
        category: 'account',
        label: newTab,
      }
    )

    setTab(newTab);
  }

  function logOut() {
    setLogoutPending(true);

    firebase.auth().signOut().then(() => {
      window.analytics.track('Logged out');
      refreshUser();
      history.push({ pathname: 'home' });
    }, function(error) {
      // An error happened.
    });

    window.analytics.track('Logged out.');
  }
  
  function cancelEdit() {
    window.analytics.track('Canceled form',
      {
        category: 'account'
      } 
    );
    setUpdatedDisplayName('');
    setUpdatedEmail('');
    setUpdatedCurrentPassword('');
    setUpdatedNewPassword('');
    setEditing();
    setDisplayNameEdited(false);
    setEmailEdited(false);
    setIsError(false);
    setIsPending(false);
    setIsValid(false);
  }

  function saveAccountSettings () {
    if (!isValid) return;

    window.analytics.track('Saved account settings',
      {
        category: 'account',
        label: editing,
      }
    )
     
    if (editing === 'password' && updatedNewPassword.length) {
      setIsPending(true);

      logIn(() => {
        const userCred = firebase.auth().currentUser;

        userCred.updatePassword(updatedNewPassword).then(() => {
          setSuccess('password');
          cancelEdit();
          refreshUser();
        }).catch(error => {
          setIsError(true);
          setIsPending(false);
        });
      })
    } else if (editing === 'email' && updatedEmail.length) {
      setIsPending(true);

      logIn(() => {
        const userCred = firebase.auth().currentUser;

        userCred.updateEmail(updatedEmail).then(() => {
          setSuccess('email');
          cancelEdit();
          refreshUser();
        }).catch(error => {
          setIsError(true);
          setIsPending(false);
        });
      })
    } else if (editing === 'displayName' && updatedDisplayName.length) {
      setIsPending(true);

      logIn(() => {
        const userCred = firebase.auth().currentUser;

        userCred.updateProfile({ displayName: updatedDisplayName }).then(() => {
          db.collection('userPrefs').doc(user.id).update({ displayName: updatedDisplayName })
          .then(() => {
            setSuccess('displayName');
            cancelEdit();
            refreshUser();
            setIsPending(false);
          });
        }).catch(error => {
          console.error(`Error setting display name: ${error}`)
          setIsError(true);
          setIsPending(false);
        });
      })
    }
  }

  function toggleEditEmail() {
    setEditing(editing !== 'email' && 'email');
  }

  function toggleEditDisplayName() {
    setEditing(editing !== 'displayName' && 'displayName');
  }

  function logIn(callback) {
    firebase.auth().signInWithEmailAndPassword(user.email, updatedCurrentPassword)
    .then(() => {
      window.analytics.track('logged in');

      return callback();
    })
    .catch(function(error) {
      setIsError(true);
      setErrorMessage(error.message); // TODO: log error.code
    });
  }

  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  function handlePrefsChange(event, preference) {
    const user = firebase.auth().currentUser;
    let tempEmailPrefs = emailPrefs;

    if (!user || !user.uid) return;

    cancelEdit();
    setSuccess();
    tempEmailPrefs[preference] = event.target.checked;
    setEmailPrefs(tempEmailPrefs)

    db.collection('userPrefs').doc(user.uid).update({ emailPrefs , userId: user.uid })
    .then((response) => {
      setSuccess(t('user-email_preference'));
      refreshUser();
    })
    .catch();
  }

  function handleChange(event, target) {
    if (target === 'displayName') setUpdatedDisplayName(event.target.value);
    else if (target === 'email') setUpdatedEmail(event.target.value);
    else if (target === 'currentPassword') setUpdatedCurrentPassword(event.target.value);
    else if (target === 'newPassword') setUpdatedNewPassword(event.target.value);

    if ((editing === 'displayName' && !displayNameEdited) || (editing !== 'displayName' && displayNameEdited)) setDisplayNameEdited(editing === 'displayName');
    if ((editing === 'email' && !emailEdited) || (editing !== 'email' && emailEdited)) setEmailEdited(editing === 'email');
  }

  function copyUrl() {
    window.analytics.track('Copied share link', {
      category: 'wishlists',
      label: wishlistUrl
    });

    shareUrl.current.select();
    document.execCommand('copy');
  }

  function setLanguage(language) {
    if (!user.id) return;

    window.analytics.track('Switched language', {
      category: 'account',
      label: language,
    });

    setLanguageLoading(language);

    db.collection('userPrefs').doc(user.id).update({ language })
    .then(() => {
      setLanguageLoading('');
      refreshUser();
    })
    .catch();
  }

  function fetchLanguages() {
    db.collection('languages').get().then(rawLanguages => {
      const languages = rawLanguages.docs.map(rl => rl.data());

      setLanguages(languages);
    });
  }

  function openWishlistBuilder(tabNumber = 0) {
    window.analytics.track('Opened wishlist builder', {
      category: 'wishlists',
      label: 'from account'
    });

    setWishlistBuilderTab(tabNumber);
    setShowWishlistBuilder(true);
  }

  function closeWishlistBuilder() {
    window.analytics.track('Closed wishlist builder', {
      category: 'wishlists',
      label: 'from account'
    });

    if(location.state) location.state.openModalFor = undefined;
    setShowWishlistBuilder(false);
  }

  useEffect(() => {
    fetchLanguages();
    window.analytics.page('account');

    if (isLoggedOut) history.push({ pathname: 'home' });
  }, [history, isLoggedOut]);

  useEffect(() => {
    if (editing === 'displayName') setIsValid(updatedDisplayName.length && (updatedDisplayName !== user.displayName) && updatedCurrentPassword.length);
    if (editing === 'email') setIsValid(Boolean(validateEmail(updatedEmail) && updatedEmail !== user.email && updatedCurrentPassword.length));
    else if (editing === 'password') setIsValid(updatedNewPassword.length >= 7);
  }, [editing, updatedDisplayName, updatedEmail, updatedCurrentPassword, updatedNewPassword, user.displayName, user.email]);

  useEffect(() => {
    function initializePublicWishlist() {
      setInitializingPublicWishlist(true);

      db.collection('public-wishlists').add({
        userId: user.id
      }).then((docRef) => {
        db.collection('userPrefs').doc(user.id).update({
          wishlistId: docRef.id
        }).then(() => refreshUser());
      })
      .catch(err => console.error(`Error creating public wishlist: ${err}`));
    }

    const userPrefs = user.userPrefs;

    if (wishlist && isLoadingWishlist) setIsLoadingWishlist(false);
    else if (!wishlist && !isLoadingWishlist) setIsLoadingWishlist(true);

    if(userPrefs && !wishlistId && !initializingPublicWishlist) initializePublicWishlist();
  }, [ user, initializingPublicWishlist, isLoadingWishlist, wishlist, wishlistId, refreshUser ]);

  useEffect(() => {
    if (!showWishlistBuilder && location.state?.openModalFor === 'edit-wishlist') openWishlistBuilder(1);
  }, [location, showWishlistBuilder]);

  return (
    <div className="account">
      <AppBar className="user-bar" position="static">
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label={t('profile')} />
          <Tab label={t('user-settings')} />
        </Tabs>
      </AppBar>

      {tab === 0 &&
        <div className="user-info">
          <div className="profile">
            <div className="header">
              <h2>{t('your_wishlist')}</h2>
              {!isMobile && <Button className="edit-wishlist" onClick={() => openWishlistBuilder(0)}>{t('edit_your_wishlist')}</Button>}
            </div>

            {isMobile && <Button className="edit-wishlist" onClick={() => openWishlistBuilder(0)}>{t('edit_your_wishlist')}</Button>}

            {displayName
              ? <>
                  {Boolean(wishlistUrl) &&
                    <p className="share-wishlist">
                      {t('share_your_wishlist')}
                      
                      <span className="copy-url">
                        <textarea readOnly ref={shareUrl} className="share-url" value={wishlistUrl} />

                        <Tooltip title={t('copy_to_clipboard')}>
                          <Button onClick={copyUrl} classes={{
                            root: 'copy-button'
                          }}>
                            <FileCopyIcon />
                          </Button>
                        </Tooltip>
                      </span>
                    </p>
                  }
                </>
              : user.id && (
                <>
                  <p>{t('set_your_display_name_desc')}</p>
                  <Button onClick={() => setTab(1)} className="go-to-settings">{t('user_add_display_name')}</Button>
                </>
              )
            }

            {isLoadingWishlist || isPending || !user.id
              ? <Spinner />
              : <>
                  <Wishlist
                    isOwn={true}
                    isPaid={isPaid}
                    openWishlistBuilder={() => openWishlistBuilder(1)}
                    promptUpgrade={promptUpgrade}
                    wishlist={wishlist}
                    t={t}
                    refreshUser={refreshUser}
                    user={user}
                  />
                </>
            }

            {showWishlistBuilder &&
              <WishlistBuilder
                {...props}
                isPaid={isPaid}
                selectedTab={wishlistBuilderTab}
                closeWishlistBuilder={closeWishlistBuilder}
                promptUpgrade={promptUpgrade}
                refreshUser={refreshUser}
              />
            }

            <div className="header">
              <h2>{t('your_badges')}</h2>
              <Button className="log-out" onClick={logOut}>{t('user-log_out')} {logoutPending && <i className="material-icons spinner">autorenew</i>}</Button>
            </div>

            {(!badges) && <Spinner />}
            {badges?.length
              ? badges.map((badge, i) => <Badge className="badge" key={`${badge.badgeKey}-${i}`} { ...badge } avatarClass="avatar" />)
              : badges && t('you_havent_earned_badges')
            }
          </div>
        </div>
      }

      {tab === 1 &&
        <div className="settings">
          <div className="header">
            <h2>{t('user-account_settings')}</h2>
            <Button className="log-out" onClick={logOut}>{t('user-log_out')} {logoutPending && <i className="material-icons spinner">autorenew</i>}</Button>
            {!isMobile && !isPaid && <Button className="upgrade-button" onClick={() => promptUpgrade(false, 'from account settings')}>{t('upgrade_your_account')}</Button>}
          </div>

          {isMobile && !isPaid && <Button className="upgrade-button" onClick={() => promptUpgrade(false, 'from account settings')}>{t('upgrade_your_account')}</Button>}

          {!(Boolean(user.email)) && <p>Loading...</p>}

          <div className="account-settings">

            {editing === 'displayName'
              ? <>
                  <FormControl className="displayName-input">
                    <InputLabel htmlFor="adornment-displayName">{t('user_displayName')}</InputLabel>
                    <Input
                      id="adornment-displayName"
                      value={displayNameEdited ? updatedDisplayName : (user.displayName || '')}
                      onChange={event => handleChange(event, 'displayName')}
                    />
                  </FormControl>

                  <FormControl className="cur-pw-input">
                    <InputLabel htmlFor="adornment-password">{t('user-current_password')}</InputLabel>
                    <Input
                      autoComplete="off"
                      id="adornment-password"
                      type="password"
                      value={updatedCurrentPassword}
                      onChange={event => handleChange(event, 'currentPassword')}
                    />
                  </FormControl>

                  <div className="account-buttons">
                    <Button onClick={cancelEdit} aria-label="Cancel">
                      {t('user-cancel')}
                    </Button>
                    <Button disabled={!isValid} onClick={saveAccountSettings} aria-label="Save">
                      {t('user-save')} {isPending && <i className="material-icons spinner">autorenew</i>}
                    </Button>
                  </div>
                </>
              : user.displayName
                ? <p>{user.displayName}</p>
                : <Button className="edit-displayName" onClick={toggleEditDisplayName} aria-label="Edit">
                    {t('user_add_display_name')}
                  </Button>
            }

            {editing === 'email' && Boolean(user.email)
              ? <>
                  <FormControl className="email-input">
                    <InputLabel shrink={true} htmlFor="adornment-email">{t('user-email')}</InputLabel>
                    <Input
                      id="adornment-email"
                      value={emailEdited ? updatedEmail : user.email}
                      onChange={event => handleChange(event, 'email')}
                    />
                  </FormControl>

                  <FormControl className="cur-pw-input">
                    <InputLabel htmlFor="adornment-password">{t('user-current_password')}</InputLabel>
                    <Input
                      autoComplete="off"
                      id="adornment-password"
                      type="password"
                      value={updatedCurrentPassword}
                      onChange={event => handleChange(event, 'currentPassword')}
                    />
                  </FormControl>

                  <div className="account-buttons">
                    <Button onClick={cancelEdit} aria-label="Cancel">
                      {t('user-cancel')}
                    </Button>
                    <Button disabled={!isValid} onClick={saveAccountSettings} aria-label="Save">
                      {t('user-save')} {isPending && <i className="material-icons spinner">autorenew</i>}
                    </Button>
                  </div>
                </>
              : <p>{user.email}</p>
            }

            {editing === 'password'
              ? <div>
                  <FormControl className="cur-pw-input">
                    <InputLabel htmlFor="adornment-password">{t('user-current_password')}</InputLabel>
                    <Input
                      autoComplete="off"
                      id="adornment-password"
                      type="password"
                      value={updatedCurrentPassword}
                      onChange={event => handleChange(event, 'currentPassword')}
                    />
                  </FormControl>

                  <FormControl className="new-pw-input">
                    <InputLabel htmlFor="adornment-password">{t('user-new_password')}</InputLabel>
                    <Input
                      id="adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      value={updatedNewPassword}
                      onChange={event => handleChange(event, 'newPassword')}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton aria-label="Toggle new password visibility" onClick={toggleShowPassword}>
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              : <div className="edit-buttons">
                  {editing !== 'email' &&
                    <Button className="edit-email" onClick={toggleEditEmail} aria-label="Edit">
                      {t('user-edit_email')}
                    </Button>
                  }

                  <Button className="update-password" onClick={() => setEditing('password')}>
                    {t('user-change_password')}
                  </Button>
                </div>
            }
          </div>

          {editing === 'password' &&
            <div className="account-buttons">
              <Button onClick={cancelEdit} aria-label="Cancel">
                {t('user-cancel')}
              </Button>
              <Button disabled={!isValid} onClick={saveAccountSettings} aria-label="Save">
                {t('user-save')} {isPending && <i className="material-icons spinner">autorenew</i>}
              </Button>
            </div>
          }

          <div className="language-select">
            {t('user-dashboard_language')}
            {languages && languages.map(language => (
              <div
                key={language.id}
                onClick={() => setLanguage(language.id)}
                className={classnames('language', { 'selected': get(user, 'userPrefs.language', 'en') === language.id })}
              >
                <img alt={`Icon for ${language.name}`} src={language.icon_url} />
                <span>{language.name}</span>
                {languageLoading === language.id && <Spinner />}
              </div>
            ))}
          </div>

          <FormControl component="fieldset">
            <FormLabel component="legend"><h4>{t('user-receive_emails_about')}</h4></FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={emailPrefs.features} onChange={event => handlePrefsChange(event, 'features')} value="features" />}
                label={t('user-new_features')}
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={isBetaMember}
                    onChange={updateBetaMembership}
                    value={isBetaMember}
                  />
                }
                label={isBetaMember ? t('youre_in_the_beta_program') : t('join_the_beta_program')}
              />
            </FormGroup>
          </FormControl>
        </div>
      }

      <Banner show={success} type="success">{t('user-update_success', { success })}</Banner>
      <Banner show={isError} type="error">{errorMessage}</Banner>
    </div>
  );
}
