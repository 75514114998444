import React, { useState } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import AsyncButton from 'react-async-button';
import { Link } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { Icon, Modal } from '@material-ui/core';

import Banner from '../Banner';
import Spinner from '../Spinner';

import './styles.scss';

export default function UpgradeModal(props) {
  const { isWishlistPrompt, onClose, refreshUser, t, user } = props;

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isSuccess, setIsSuccess] = useState(false);

  const initialTitle = isWishlistPrompt ? t('upgrade_your_account_to_add') : t('upgrade_your_account');
  const title = isSuccess ? t('thank_you_for_upgrading') : initialTitle;
  
  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    setErrorMessage(undefined);

    const cardElement = elements.getElement(CardElement);

    const { error: cardError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (cardError) {
      window.analytics.track('Card error',
        {
          category: 'upgrade account',
          label: cardError.message,
        } 
      );

      return setErrorMessage(cardError.message);
    }

    const intent = await fetch('https://us-central1-fortnite-dashboard-b545a.cloudfunctions.net/upgrade-account', {
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(res => res.json())
    .catch(err => setErrorMessage(err));

    if (!intent) return;

    const { client_secret } = intent;

    const result = await stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: cardElement,
      }
    });

    if (result.error) {
      return setErrorMessage(result.error.message);
    } else {
      if (result.paymentIntent?.status === 'succeeded') {

        await firebase.auth().currentUser.getIdToken(false).then(async idToken => {
          const payload = {
            idToken,
            userId: user.id
          }

          await fetch('https://us-central1-fortnite-dashboard-b545a.cloudfunctions.net/update-paid-account', {
            method: 'POST',
            cache: 'no-cache',
            mode: 'no-cors',
            body: JSON.stringify(payload),
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(() => {
            setErrorMessage(undefined);
            refreshUser();
            setIsSuccess(true);
          }).catch(err => setErrorMessage(err.message));
        }).catch(function(error) {
          setErrorMessage(error)
        });
      } else setErrorMessage(result.paymentIntent?.error?.message);
    }

    return paymentMethod;
  };

  function getSuccessView() {
    return (
      <div className="success-view">
        <p>{t('your_payment_has_been_processed')}</p>

        <Link className="edit-wishlist" to={{
            pathname: "/account",
            state: { openModalFor: 'edit-wishlist' }
          }}
        >
          {t('edit_your_wishlist')}
        </Link>
      </div>
    )
  }

  return (
    <Modal open={true} onClose={onClose}>
      <div className="upgrade-modal">
        <header>
          <h3>{title}</h3>
          <Icon className="close" onClick={onClose}>close</Icon>
        </header>

        <Banner className="error-banner" show={errorMessage} message={errorMessage} />

        {!isSuccess &&
          <>
            <p>{t('upgrading_your_account_desc')}</p>
            <p>{t('youll_receive_these_features')}</p>

            <ul>
              <li>
                <p className="label">{t('unlimited_wishlist_items')}</p>
                <p className="desc">{t('unlimited_wishlist_items_desc')}</p>
              </li>
              <li>
                <p className="label">{t('beta_invites')}</p>
                <p className="desc">{t('beta_invites_desc')}</p>
              </li>
              <li>
                <p className="label">{t('exclusive_badges')}</p>
                <p className="desc">{t('exclusive_badges_desc')}</p>
              </li>
              <li>
                <p className="label">{t('support_the_site')}</p>
                <p className="desc">{t('support_the_site_desc')}</p>
              </li>
            </ul>

            <div className="pay-view">
              <CardElement options={{
                style: {
                  base: {
                    color: "#222",
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "18px",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#fa755a",
                    iconColor: "#fa755a",
                  },
                },
              }}
              />
            </div>

            <div className="content">
              <img src="assets/icons/powered_by_stripe.png" alt={t('powered_by_stripe')} />
              
              <AsyncButton
                className="submit"
                onClick={handleSubmit}
                pendingText={t('processing')}
                text={t('get_three_months_for', { price: '$3' })}
              >
                {({buttonText, isPending}) => (
                  <>
                    {buttonText}

                    {isPending && <Spinner />}
                  </>
                )}
              </AsyncButton>
            </div>
          </>
        }

        {isSuccess && getSuccessView()}
      </div>
    </Modal>
  )
}