import React, { Component } from 'react';

import './styles.scss';

export default class Spinner extends Component {
  render () {
    const { className } = this.props;

    return (
      <i className={`material-icons spinner ${className}`}>autorenew</i>
    );
  }
}
