import React, { Component } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';

import { firebaseConfig } from '../../constants.js';

import { IconButton, LinearProgress, Modal } from '@material-ui/core/';

import './styles.scss';

const firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] : firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();


export default class Donate extends Component {
  state = { showDonateModal: false };

  componentDidMount () {
    this.getData();
  }

  render () {
    const { breakpoint } = this.props;
    const { donations = 0, goal = 100, donorList = [], showDonateModal } = this.state;
    const completed = donations >= goal ? 100 : (donations / goal) * 100;
    const currentMonth = moment().format('MMMM');

    return (
      <>
        <div className="donate">
          {breakpoint !== 'mobile' &&
            <div className='cta'>
              <p className="links">
                <a onClick={() => this.trackClick('tweet')} href="https://twitter.com/Fortnite_Dash" target="_blank" rel="noopener noreferrer">tweet</a>
                <a onClick={() => this.trackClick('github')} href="https://github.com/robeau" target="_blank" rel="noopener noreferrer">github</a>
                <a onClick={() =>this.trackClick('loyal llama')} href="https://drive.google.com/drive/folders/1s4oO9-Bi5lpaq9P26rew-X8S2Fil22ck" target="_blank" rel="noopener noreferrer">loyal llama app (android only)</a>
              </p>

              <div className="cta-link-container" onClick={this.handleDonateClick}>
                <p>Donations keep this site up. Click here to pitch in.</p>
              </div>
            </div>
          }

          <div className='donation-tracker' onClick={this.handleDonateClick}>
            <p className="amounts"><span className="raised">Raised: ${donations}</span><span>Goal for {currentMonth}: ${goal}</span></p>
            <LinearProgress className="progress-bar" variant="determinate" value={completed} />
            {Boolean(donorList.length) && <p className="donor-list">Thanks to Fortnite Dash's {currentMonth} donors: {donorList}</p>}
          </div>
        </div>

        <Modal
          open={Boolean(showDonateModal)}
          onClose={() => this.setState({ showDonateModal: false })}
        >
          <div className="confirmation-modal">
            <IconButton className="close" onClick={() => this.setState({ showDonateModal: false })}>
              <i className="material-icons">close</i>
            </IconButton>

            <p className="first">This site runs on donations. New features are made possible because of generous users like you.</p>
            <p>Click the link below to donate through PayPal.</p>
            <p>Include a note on the payment screen if you'd like your name featured on the thank you list.</p>

            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="RUPNB5V2EBSUL" />
              <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
              <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>

          </div>
        </Modal>
      </>
    );
  }

  trackClick = linkType => {
    window.analytics.track('Clicked link', {
      label: linkType
    });
  }

  handleDonateClick = () => {
    window.analytics.track('Clicked Donate CTA');

    this.setState({ showDonateModal: true });
  }

  getData = () => {
    db.collection('misc').get().then(result => {
      if (result && !result.empty) {
        const donationData = result.docs[0].data();
        const { amountRaised, goal, donorList } = donationData;

        this.setState({
          donations: amountRaised,
          goal: goal,
          donorList: donorList
        });
      }
    }).catch();
  }
}
