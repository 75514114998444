import React, { Component } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import './styles.scss';

export default class Badge extends Component {
  render () {
    const { avatarClass, className, imageSrc, badgeKey, label } = this.props;

    return (
      <Chip
        avatar={<Avatar className={avatarClass} alt={`Badge icon for ${label}`} src={imageSrc || `/assets/icons/badges/${badgeKey}-icon.png`} />}
        label={label}
        className={className}
      />
    );
  }
}
