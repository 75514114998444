import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
  root: {
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    top: 36,
    right: 0,
    left: 0,
  },
  fake: {
    backgroundColor: grey[200],
    height: theme.spacing(1),
    margin: theme.spacing(2),
    // Selects every two elements among any group of siblings.
    '&:nth-child(2n)': {
      marginRight: theme.spacing(3),
    },
  },
});

class DropdownMenu extends React.Component {
  state = {
    open: false,
  };

  handleClick = () => {
    this.setState(state => ({
      open: !state.open,
    }));
  };

  handleClickAway = () => {
    this.setState({
      open: false,
    });
  };

  openButton = React.createRef();

  render() {
    const { buttonClass, buttonIcon = 'filter_list', className, dropdownLabel, items, menuClass, noIcon, subItems } = this.props;
    const { open } = this.state;

    return (
      <div className={className}>
        <IconButton ref={this.openButton} className={buttonClass} onClick={this.handleClick}>
          {noIcon
            ? dropdownLabel
            : <i className="material-icons">{buttonIcon}</i>
          }
        </IconButton>

        <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          id="simple-menu"
          anchorEl={this.openButton.current}
          classes={{ paper: menuClass }}
          keepMounted
          open={open}
          onClose={this.handleClickAway}
        >
          {items.map((item, i) => (
            <MenuItem key={`menu-${i}`} onClick={this.handleClickAway}>{item}</MenuItem>
          ))}
          {subItems && subItems.length && <hr />}
          {subItems && subItems.map((item, i) => (
            <MenuItem key={`menu-${i}`} onClick={this.handleClickAway}>{item}</MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

DropdownMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DropdownMenu);
