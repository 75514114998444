import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import './styles.scss';

export default class MediaCard extends Component {
  render() {
    const { cardActions, content, imageAlt, imageClass, imageSrc, onClick, title } = this.props;

    return (
      <Card className="card" onClick={onClick}>
        <CardActionArea className="card-action">
          <CardMedia
            className={`card-image ${imageClass}`}
            image={imageSrc}
            title={imageAlt}
          />
          <CardContent className="card-content">
            <Typography className="card-title" gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Typography className="card-description" variant="body2" color="textSecondary" component="p">
              {content}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          {cardActions}
        </CardActions>
      </Card>
    );
  }
}
