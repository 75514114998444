import React, { useCallback, useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { get } from 'lodash';

import { firebaseConfig } from '../../constants.js';

import Spinner from '../Spinner';

import {
    AppBar,
    Button,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Tab,
    Tabs,
    TextField } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import './styles.scss';

const firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] : firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

const platforms = ['playstation', 'xbox', 'switch', 'pc', 'mobile'];

export default function TeamUp(props) {
    const { className, t, user } = props;

    const [expires, setExpires] = useState(1);
    const [helpers, setHelpers] = useState([]);
    const [helpersPending, setHelpersPending] = useState(false);
    const [helperValues, setHelperValues] = useState({ platform: '', gamertag: '', region: ''});
    const [notificationPending, setNotificationPending] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);
    const [showHelper, setShowHelper] = useState(false);

    const helperRef = useRef(null);

    function openModal() {
      setShowHelper(true);

      window.analytics.track('Clicked Team Up! button'); // TODO: Add label with page name
    }

    function closeModal() {
        setShowHelper(false);
    }

    function handleTabChange(event, selectedTab, isCTA) {
        event.stopPropagation();

        if (isCTA) {
            window.analytics.track('Clicked add name to list');
        }
        else {
            const tabs = ['View', 'Join']

            window.analytics.track('Switched helper tab', {
                label: tabs[selectedTab]
            });
        }

        setSelectedTab(selectedTab);
    }

    const fetchHelpersCallback = useCallback(
        () => {
            setHelpersPending(true);
    
        db.collection('helpers').where('addedTime', '>=', 1).get().then(helpersRaw => {
          setHelpersPending(false);
    
          if (helpersRaw && !helpersRaw.empty) {
            const helpers = helpersRaw.docs.map(item => ({ ...item.data(), id: item.id }));
    
            window.analytics.track('Fetched helpers', {
              label: helpers.length
            });
    
            helpers.forEach(helper => {
              const current = Date.now();
    
              if (current - helper.addedTime > 3600000) removeHelper(helper);
            })
    
            helpers.sort((a, b) => a.addedTime - b.addedTime);
    
            setHelpers(helpers);
          }
        });
        },
        [],
      );

    function fetchHelpers() {
        setHelpersPending(true);
    
        db.collection('helpers').where('addedTime', '>=', 1).get().then(helpersRaw => {
          setHelpersPending(false);
    
          if (helpersRaw && !helpersRaw.empty) {
            const helpers = helpersRaw.docs.map(item => ({ ...item.data(), id: item.id }));
    
            window.analytics.track('Fetched helpers', {
              label: helpers.length
            });
    
            helpers.forEach(helper => {
              const current = Date.now();
    
              if (current - helper.addedTime > 3600000) removeHelper(helper);
            })
    
            helpers.sort((a, b) => a.addedTime - b.addedTime);
    
            setHelpers(helpers);
          }
        });
    }

    function removeHelper(helper) {
        db.collection('helpers').doc(helper.id).delete();
    }

    function handleEdit(event, target) {
        const helperValuesCopy = { ...helperValues };

      event.stopPropagation();

      if (target === 'phoneNumber') {
        const phoneNumber = event.target.value.replace(/\D/g,'');
        setPhoneNumber(phoneNumber);
      } else {
        helperValuesCopy[target] = event.target.value;
        setHelperValues(helperValuesCopy);
      }
    }

    function submitNumber () {
        const date = new Date();
        const current = Math.floor(date.getTime() / 1000);
        const expiresUnix = Math.floor(current + (+expires * 3600));
    
        if (!phoneNumber || phoneNumber.length !== 10 || !expires) return;
    
        const number = '+1' + phoneNumber;
    
        setNotificationPending(true);
    
        window.analytics.track('Added phone number');
    
        db.collection('helpers').doc('notifications').get()
        .then(rawNotifications => {
          const { sms } = rawNotifications.data();
          const existingSmsIndex = sms.findIndex(item => item.number === number);
    
          if (existingSmsIndex >= 0) sms[existingSmsIndex].expires = expiresUnix;
          else {
            sms.push({
              expires: expiresUnix,
              number
            });
          }
    
          db.collection('helpers').doc('notifications').update({
            sms
          }).then(() => resetNotificationSection(true));
        })
    }

    function resetNotificationSection() {
      setExpires(1);
      setPhoneNumber('');
      setNotificationPending(false);
    }

    function addHelper() {
      setHelpersPending(true);
  
      db.collection('helpers').doc().set({
        ...helperValues,
        addedTime: Date.now()
      }).then(() => {
        let label = `${helperValues.gamertag} | ${helperValues.region} | ${helperValues.platform}`;
  
        if (user.id) label += ` | ${user.id}`;
  
        window.analytics.track('Added helper', {
          label
        });
  
        setHelpersPending(false);
        setSelectedTab(0);
        fetchHelpers();
      });
    }

    useEffect(() => {
        if (get(helperRef, 'current') ) helperRef.current.scrollTo(0, 0);
    }, [selectedTab, showHelper, helperRef]);

    useEffect(() => {
        fetchHelpersCallback();
    }, [fetchHelpersCallback]);

    return (
        <div className={`team-up ${className}`}>
            <button className="team-up-button" onClick={openModal}>
                Team Up!
            </button>

            <Modal className="team-up-modal" open={Boolean(showHelper)} onClose={closeModal}>
                <div ref={helperRef} className="helper">
                    <AppBar position="static">
                        <Tabs className="helper-tabs" value={selectedTab} onChange={handleTabChange}>
                        <Tab label={t('helper-view_list')} />
                        <Tab label={t('helper-join_list')} />
                        <IconButton onClick={closeModal} className="close-button" aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        </Tabs>
                    </AppBar>
                    {selectedTab === 0 &&
                        <div className="helper-form">
                            <p>{t('add_players_from')}</p>
                            <p>{t('names_are_removed')}</p>

                            {helpers.length
                                ? <>
                                    <ul className="helper-list">
                                    {helpers.map((helper, i) => (
                                        <li key={`helper-${i}`}>
                                        <img src={`/assets/platforms/${helper.platform}.png`} alt={helper.platform} />
                                        {helper.gamertag} {get(helper, 'region.length') ? `(${helper.region})` : ''}
                                        </li>
                                    ))}
                                    </ul>
                                    <Button className="helper-button" onClick={fetchHelpers}>{t('refresh_list')} {helpersPending && <Spinner />}</Button>
                                </>
                                : <>
                                    <span className="helper-list">{t('no_players_yet')}</span>
                                    <Button className="helper-button" onClick={(event) => handleTabChange(event, 1, true)}>{t('add_your_name')}</Button>
                                </>
                            }

                            <hr />

                            <h4>{t('notification_title')}</h4>

                            <p>{t('notification_message')}</p>
                            <p>{t('message_and_data_rates')}</p>

                            <div className="notification-input-section">
                                <FormControl className="phone-number">
                                    <InputLabel htmlFor="adornment-phone">Phone Number</InputLabel>
                                    <Input
                                    id="adornment-phone"
                                    value={phoneNumber}
                                    onChange={event => handleEdit(event, 'phoneNumber')}
                                    startAdornment={<InputAdornment position="start">+1</InputAdornment>}
                                    type="tel"
                                    />
                                    <div className="expires-container">
                                    <p>{t('stop_sending')}:</p>

                                    <Button
                                        onClick={() => setExpires(1)}
                                        className={classnames('expires', { 'active': expires === 1 })}
                                    >
                                        1 {t('hour')}
                                    </Button>
                                    <Button
                                        onClick={() => setExpires(3)}
                                        className={classnames('expires', { 'active': expires === 3 })}
                                    >
                                        3 {t('hours')}
                                    </Button>
                                    <Button
                                        onClick={() => setExpires(8)}
                                        className={classnames('expires', { 'active': expires === 8 })}
                                    >
                                        8 {t('hours')}
                                    </Button>
                                    </div>
                                </FormControl>

                                <Button
                                    disabled={phoneNumber.length !== 10}
                                    className="notify-me-button"
                                    onClick={submitNumber}
                                >
                                    {t('notify_me')} {notificationPending && <Spinner />}
                                </Button>
                            </div>
                        </div>
                    }
                    {selectedTab === 1 &&
                        <>
                        <FormControl className="platform-form">
                            <InputLabel htmlFor="selected-platform">{t('platform')}</InputLabel>
                            <Select
                                value={helperValues.platform}
                                inputProps={{
                                    name: 'selected-platform',
                                    id: 'selected-platform',
                                }}
                                onChange={(event) => setHelperValues({...helperValues, platform: event.target.value})}
                                >
                                {platforms.map((item, i) => (
                                    <MenuItem key={`platform-menu-${i}`} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                            <TextField className="input" id="gamertag" label="Gamertag" value={helperValues.gamertag} onChange={event => handleEdit(event, 'gamertag')} />
                            <TextField className="input" id="region" label="Region" value={helperValues.region} onChange={event => handleEdit(event, 'region')} />
                            <Button className="helper-button" disabled={!get(helperValues, 'gamertag.length')} onClick={addHelper}>{t('add')} {helpersPending && <Spinner />}</Button>
                        </FormControl>
                        </>
                    }
                </div>
            </Modal>
        </div>
    );
}