import "@babel/polyfill";
import 'core-js';
import 'raf/polyfill';

import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import GlobalConsumer from './App';

import './styles.scss';

function AppRouter() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" render={props => <GlobalConsumer {...props} section={'home'} />} />
          <Route exact path="/account" render={props => <GlobalConsumer {...props} section={'account'} />} />
          <Route exact path="/home" render={props => <GlobalConsumer {...props} section={'home'} />} />
          <Route path="/missions/:openMission?" render={props => <GlobalConsumer {...props} section={'missions'} />} />
          <Route path="/map/:openChallenge?" render={props => <GlobalConsumer {...props} section={'map'} />} />
          <Route path="/news/:articleId?" render={props => <GlobalConsumer {...props} section={'news'} />} />
          <Route path="/wishlist/:wishlistId?" render={props => <GlobalConsumer {...props} section={'public-wishlist'} />} />
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </Router>
  );
}

function PageNotFound() {
  return (
    <div className="home">
      <p>Page not found</p>
    </div>
  );
}

export default AppRouter;
