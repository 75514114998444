export const translations = {
  en: {
    account: 'Account',
    add: 'Add',
    add_items_to_your_wishlist: 'Add items to your wishlist',
    add_players_from: 'Here\'s a list of players who are online and want to work on challenges together.',
    add_your_name: 'Add your name to the list',
    all_items: 'All Items',
    beta_invites: 'Beta Invites',
    beta_invites_desc: 'Get the opportunity to test new features before they launch (and receive badges for trying them out).',
    by: 'by {{author}}',
    click_all_items_tab: 'Click the All Items tabs to add items to your wishlist.',
    copy_to_clipboard: 'Copy to Clipboard',
    create_a_wishlist: 'Create a wishlist',
    dismiss: 'Dismiss',
    edit_your_own: 'Edit your own wishlist by clicking Account.',
    edit_your_wishlist: 'Edit your wishlist',
    exclusive_badges: 'Exclusive Badges',
    exclusive_badges_desc: 'Some badges will only be attainable with an upgraded account.',
    featured_sac_use_code: 'Featured SAC! Use code:',
    get_three_months_for: 'Get three months for {{price}}',
    'helper-join_list': 'Join List',
    'helper-view_list': 'View List',
    home: 'Home',
    hour: 'hour',
    hours: 'hours',
    join_the_beta_program: 'Join the Beta program',
    loading: 'Loading...',
    login_or_create_to_make: 'Login or create a free account to make your own wishlist.',
    map: 'Map',
    message_and_data_rates: 'Message and data rates may apply, depending on your service provider.',
    missions: 'Missions',
    'missions-completed': 'Completed',
    'missions-get_help': 'Get help',
    'missions-mark_completed': 'Mark Completed',
    'missions-need_help_with': 'Need a buddy for \'Boogie Down: Raise a disco ball with others\'?',
    'missions-view_challenges': 'View Challenges',
    'missions-view_on_map': 'View on Map',
    names_are_removed: 'Each name is automatically removed from the list after an hour.',
    news: 'News',
    news_blurb: 'The News section is new and growing! If you\'d like to contribute an article, send an email to ',
    'no_players_yet': 'No players yet',
    no_items_in_wishlist: '{{name}} doesn\'t have any items in their wishlist right now.',
    notification_message: 'If you have a 10-digit US phone number, enter it to receive a notification when a new name is added to the list.',
    notification_title: 'Want to get a text when someone joins the list?',
    notify_me: 'Notify me',
    platform: 'Platform',
    posted: 'Posted {{date}}',
    processing: 'Processing',
    profile: 'Profile',
    powered_by_stripe: 'Powered by Stripe',
    'refresh_list': 'Refresh List',
    sac_instructions: 'While playing Fortnite, look in the shop and click the "Support a Creator" button on the bottom right (press square on PS4), then type {{sac}} in the box and click Accept.',
    send_an_email: 'Send an email to {{link}} to discuss collaboration or other deals.',
    set_unlimited_items: 'Set unlimited wishlist items and support the site.',
    set_wishlist: 'Once you\'ve added items to your wishlist, they\'ll appear here.',
    set_your_display_name: 'Set your display name',
    set_your_display_name_desc: 'Set your display name in Settings in order to share your wishlist publicly.',
    share_your_wishlist: 'Use this link to share your wishlist with friends:',
    stop_sending: 'Stop sending after',
    submit: 'Submit',
    support_the_site: 'Support the site',
    support_the_site_desc: 'Users like you are the reason why Fortnite Dashboard is around. Thanks to your support, I\'ve been able to continue to add new features consistently.',
    thank_you_for_upgrading: 'Thank you for upgrading!',
    this_is_your_wishlist: 'This is your wishlist. You\'ll receive an email if any of your wishlisted items is in the item shop when it updates.',
    this_user: 'This user',
    unlimited_wishlist_items: 'Unlimited Wishlist Items',
    unlimited_wishlist_items_desc: 'Add as many items as you want to your wishlist',
    upgrade_your_account: 'Upgrade your account',
    upgrade_your_account_to_add: 'Upgrade your account to add more items',
    upgrading_your_account_desc: 'Support Fortnite Dashboard with an account upgrade.',
    'user-account_settings': 'Account Settings',
    user_add_display_name: 'Add your Fortnite in-game name',
    'user-badges': 'Badges',
    'user-cancel': 'Cancel',
    'user-change_password': 'Change Password',
    'user-current_password': 'Current Password',
    'user-dashboard_language': 'Dashboard Language:',
    user_displayName: 'Your Fortnite name',
    'user-edit_email': 'Edit Email',
    'user-email': 'Email',
    'user-email_preference': 'email preference',
    'user-log_out': 'Log out',
    'user-new_challenges': 'New Challenges (usually weekly)',
    'user-new_features': 'New / updated dashboard features (usually monthly)',
    'user-new_password': 'New Password',
    'user-profile_badges': 'Profile Badges',
    'user-receive_emails_about': 'Receive Emails About:',
    'user-save': 'Save',
    'user-settings': 'Settings',
    'user-update_success': 'Your {{success}} was updated successfully!',
    users_wishlist: '{{name}}\'s Wishlist',
    'view_challenge_sheet': 'View Challenge Sheet',
    view_your_wishlist: 'View your Wishlist',
    want_feature: 'Want to be the featured creator?',
    wishlist: 'Wishlist',
    wishlist_not_found: 'Sorry, we couldn\'t find the wishlist you\'re looking for. Please try again later.',
    you_dont_have_items: 'You don\'t have any items in your wishlist.',
    you_havent_earned_badges: 'You haven\'t earned any badges yet.',
    'youll_need_one': 'You\'ll need one other person to raise the disco ball.',
    youll_receive_these_features: 'You\'ll unlock these features:',
    your_badges: 'Your badges',
    your_payment_has_been_processed: 'Your payment has been processed, and you can start using all features immediately.',
    your_wishlist: 'Your Wishlist',
    youre_in_the_beta_program: 'You\'re in the Beta program.',
    youre_viewing_wishlist: 'You\'re viewing someone else\'s wishlist.'
  },
  es: {
    account: 'Cuenta',
    add: 'Añadir',
    add_items_to_your_wishlist: 'Agregue elementos a su lista de deseos',
    add_players_from: 'Aquí hay una lista de jugadores que están en línea y quieren trabajar juntos en desafíos.',
    add_your_name: 'Añade tu nombre a la lista',
    all_items: 'Todos los artículos',
    beta_invites: 'Invitaciones Beta',
    beta_invites_desc: 'Get the opportunity to test new features before they launch (and receive badges for trying them out).',
    by: 'por {{author}}',
    click_all_items_tab: 'Haga clic en las pestañas Todos los artículos para agregar artículos a su lista de deseos.',
    copy_to_clipboard: 'Copiar al portapapeles',
    create_a_wishlist: 'Crea una lista de deseos',
    dismiss: 'Rechazar',
    edit_your_own: 'Edite su propia lista de deseos haciendo clic en Cuenta.',
    edit_your_wishlist: 'Edita tu lista de deseos',
    exclusive_badges: 'Insignias exclusivas',
    exclusive_badges_desc: 'Algunas insignias solo serán alcanzables con una cuenta actualizada.',
    featured_sac_use_code: 'SAC destacado! Usa el código:',
    get_three_months_for: 'Obtenga tres meses por {{price}}',
    'helper-join_list': 'Unirse a la Lista',
    'helper-view_list': 'Ver Lista',
    home: 'Inicio',
    hour: 'hora',
    hours: 'horas',
    join_the_beta_program: 'Únete al programa Beta',
    loading: 'Cargando...',
    login_or_create_to_make: 'Inicie sesión o cree una cuenta gratuita para hacer su propia lista de deseos.',
    map: 'Mapa',
    message_and_data_rates: 'Se pueden aplicar tarifas de mensajes y datos, dependiendo de su proveedor de servicios.',
    missions: 'Misiones',
    'missions-completed': 'Completadas',
    'missions-get_help': 'Consigue ayuda',
    'missions-mark_completed': 'Marque Completada',
    'missions-need_help_with': '¿Necesitas un amigo para \'Boogie Down: Levanta una bola de discoteca con otros\'?',
    'missions-view_challenges': 'Ver Desafíos',
    'missions-view_on_map': 'Ver en el Mapa',
    names_are_removed: 'Los nombres se eliminan automáticamente de la lista después de una hora',
    news: 'Noticias',
    news_blurb: '¡La sección de Noticias es nueva y está creciendo! Si desea contribuir con un artículo, envíe un correo electrónico a ',
    'no_helpers_yet': 'Aún no hay jugadores',
    no_items_in_wishlist: '{{name}} no tiene ningún artículo en su lista de deseos en este momento.',
    notification_message: 'Si tiene un número de teléfono de Estados Unidos de 10 dígitos, ingréselo para recibir una notificación cuando se agregue un nuevo nombre a la lista.',
    notification_title: '¿Quieres recibir un mensaje de texto cuando alguien se une a la lista?',
    notify_me: "Notificarme",
    platform: 'Platforma',
    posted: 'Publicado el {{date}}',
    processing: 'Procesando',
    profile: 'Perfil',
    powered_by_stripe: 'Desarrollado por Stripe',
    'refresh_list': 'Actualizar la lista',
    sac_instructions: 'Mientras juegas a Fortnite, busca en la tienda y haz clic en el botón "Apoyar a un creador" en la parte inferior derecha (presiona cuadrado en PS4), luego escribe {{sac}} en el cuadro y haz clic en Aceptar.',
    send_an_email: 'Envíe un correo electrónico a {{link}} para discutir la colaboración u otras ofertas.',
    set_unlimited_items: 'Establezca elementos de lista de deseos ilimitados y apoye el sitio.',
    set_wishlist: 'Una vez que haya agregado elementos a su lista de deseos, aparecerán aquí.',
    set_your_display_name: 'Establece tu nombre para mostrar',
    set_your_display_name_desc: 'Establezca su nombre para mostrar en Configuración para compartir su lista de deseos públicamente.',
    share_your_wishlist: 'Use este enlace para compartir su lista de deseos con amigos:',
    stop_sending: 'Dejar de enviar después de',
    submit: 'Enviar',
    support_the_site: 'Apoye el sitio',
    support_the_site_desc: 'Los usuarios como tú son la razón por la que Fortnite Dashboard existe. Gracias a su apoyo, he podido continuar agregando nuevas funciones de manera consistente.',
    thank_you_for_upgrading: '¡Gracias por actualizar!',
    this_is_your_wishlist: 'Esta es tu lista de deseos. Debería recibir un correo electrónico si alguno de sus artículos de la lista de deseos está en la tienda de artículos cuando se actualiza.',
    this_user: 'Esta usuaria o usario',
    unlimited_wishlist_items: 'Artículos de lista de deseos ilimitados',
    unlimited_wishlist_items_desc: 'Agregue tantos elementos como desee a su lista de deseos',
    upgrade_your_account: 'Actualiza tu cuenta',
    upgrade_your_account_to_add: 'Actualice su cuenta para agregar más elementos',
    upgrading_your_account_desc: 'Admite Fortnite Dashboard con una actualización de cuenta de un mes.',
    'user-account_settings': 'Configuraciones de la cuenta',
    user_add_display_name: 'Agrega tu nombre Fortnite en el juego',
    'user-badges': 'Medallas',
    'user-cancel': 'Cancelar',
    'user-change_password': 'Cambia la contraseña',
    'user-current_password': 'Contraseña Actual',
    'user-dashboard_language': 'Lenguaje de la Dashboard:',
    user_displayName: 'Tu nombre Fortnite',
    'user-edit_email': 'Editar E-mail',
    'user-email': 'E-mail',
    'user-email_preference': 'preferencia de e-mail',
    'user-log_out': 'Cerrar sesión',
    'user-new_challenges': 'Nuevos desafios (generalmente semanal)',
    'user-new_features': 'Nuevas características del dashboard (generalmente mensual)',
    'user-new_password': 'Nueva Contraseña',
    'user-profile_badges': 'Insignias de perfil',
    'user-receive_emails_about': 'Recibir correos electrónicos sobre:',
    'user-save': 'Salvar',
    'user-settings': 'Ajustes',
    'user-update_success': 'Su {{success}} fue actualizado con éxito!',
    users_wishlist: 'Lista de deseos de {{name}}',
    'view_challenge_sheet': 'Ver Hoja de Desafío',
    view_your_wishlist: 'Ver tu lista de deseos',
    want_feature: '¿Quieres ser el creador destacado?',
    wishlist: 'Lista de deseos',
    wishlist_not_found: 'Lo sentimos, no pudimos encontrar la lista de deseos que estás buscando. Por favor, inténtelo de nuevo más tarde.',
    you_dont_have_items: 'No tienes ningún artículo en tu lista de deseos.',
    you_havent_earned_badges: 'Aún no has ganado ninguna insignia.',
    'youll_need_one': 'Necesitarás a otra persona para levantar la bola de discoteca.',
    youll_receive_these_features: 'Recibirá estas características:',
    your_badges: 'Sus insignias',
    your_payment_has_been_processed: 'Su pago ha sido procesado y puede comenzar a usar todas las funciones de inmediato.',
    your_wishlist: 'Tu lista de deseos',
    youre_in_the_beta_program: 'Estás en el programa Beta.',
    youre_viewing_wishlist: 'Estás viendo la lista de deseos de otra persona.'
  }
};
