import React from 'react';

import classnames from 'classnames';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import './styles.scss';

export default function Banner(props) {
  const { children, className, dismissable, message, onClose, show, type } = props;

  return (
    <div className={classnames('banner', className, type, { 'show': show })}>
      {children || message}

      {dismissable &&
        <IconButton className="close-button" aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
    </div>
  );
}
