import React, { useEffect, useRef, useState } from 'react';

import { get } from 'lodash';
import { Link } from 'react-router-dom';
import 'whatwg-fetch';

import { Button } from '@material-ui/core';

import LoginPrompt from '../../components/LoginPrompt';

import { translations } from '../../i18n.js';

import './styles.scss';

export default function Map(props) {
  const { openAccountCreation, user } = props;

  const missionsRef = useRef(null);
  const [languageMap, setLanguageMap] = useState(translations[get(user, 'userPrefs.language', 'en')]);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  function t(key, names) {
    let str = languageMap[key];

    for (const name in names) {
      str = str.replace(`{{${name}}}`, names[name])
    }

    return str;
  }

  const promptLogin = (label) => {
    window.analytics.track('Get login prompt', {
      category: 'homepage',
      label
    });

    setShowLoginPrompt(true);
    window.analytics.track('Prompted to log in');
  }

  useEffect(() => {
    setLanguageMap(translations[get(user, 'userPrefs.language', 'en')]);
  }, [user]);

  return (
    <div ref={missionsRef} className="map-container">
      {showLoginPrompt &&
          <LoginPrompt
            onClose={() => setShowLoginPrompt(false)}
            openAccountCreation={openAccountCreation}
          />
        }

        <p>Since you're here, it's likely you've used my challenge sheets in the past.</p>
        <p>I've stopped maintaining those, because in Chapter 2 Season 2, Epic greatly improved Fortnite's in-game mission system. They added an interactive challenge map, and also added nearby challenges to the HUD. I'd like to think this improvement was spurred by my site and others like it, but who knows.</p>
        <p>Going forward, I'll be able to dedicate more time to the wishlist builder and any other new features that come up. (At least until Epic adds that the the game too. XD)</p>
        <p>- CaliforniaQT</p>

        <div>
          {user.id
            ? <Link className="view-button view-wishlist" to={'/account'}>{user?.userPrefs?.hasWishlist ? t('view_your_wishlist') : t('create_a_wishlist')}</Link>
            : <Button className="view-button view-wishlist" onClick={() => promptLogin('from item shop')}>
                {user?.userPrefs?.hasWishlist ? t('view_your_wishlist') : t('create_a_wishlist')}
              </Button>
          }
        </div>
    </div>
  );
}
