import React, { useEffect, useState } from 'react';

import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField
} from '@material-ui/core';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { firebaseConfig } from '../../../../constants.js';

import './styles.scss';

const firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] :  firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

export default function Gifters (props) {
  const { onClose } = props;

  const [isPending, setIsPending] = useState(true);
  const [receivers, setReceivers] = useState([]);
  const [values, setValues] = useState({ bannerType: 'Any Banner' });

  const getReceivers = async (event) => {
    if (event) event.stopPropagation();

    const receiversFetched = await db.collection('receivers').where('gamertag', '>', '').get()
    .then((raw) => {
      if (!raw.docs || raw.empty) return;

      return raw.docs.map(item => item.data());
    });

    if (receiversFetched) setReceivers(receiversFetched);
  }

  const addReceiver = async () => {
    const { bannerType, gamertag, region = '' } = values;

    window.analytics.track('Adding info to gifting tool', {
      bannerType,
      gamertag,
      region
    });

    setIsPending(true);

    db.collection('receivers').doc().set({
      bannerType,
      gamertag,
      region
    }).then(() => getReceivers());
  }

  useEffect(() => {
    getReceivers();
  }, []);

  useEffect(() => {
    if (isPending) setIsPending(false);
  }, [isPending, receivers]);

  return (
    <Modal open={true} onClose={onClose}>
      <div className="gifters">
        <header>
          <h2>Give and receive banners</h2>
          <IconButton className="close" onClick={onClose}>
            <i className="material-icons">close</i>
          </IconButton>
        </header>

        <div className="receivers">
          <h3>Send a banner</h3>

          Here's a list of people who'd like a banner. Send one if you're feeling generous. It's free!

          <ul className="receivers-list">
            {receivers.map((receiver, i) => (
              <li key={`receiver-${i}`}>
                {receiver.gamertag} - {receiver.bannerType}
              </li>
            ))}
          </ul>

          <Button className="refresh-button" onClick={getReceivers}>Refresh List {isPending && <i className="material-icons spinner">autorenew</i>}</Button>
        </div>


        <div className="rest">
          <h3>Add your name to the list</h3>

          <p>Add your in game name to this list to indicate that you'd like to receive a banner.</p>
          <p>I'll make a way to remove your name soon, until then, names will be removed after a few hours.</p>
          <FormControl className="platform-form">
            <TextField className="input" id="gamertag" label="Gamertag" value={values.gamertag} onChange={event => setValues({ ...values, gamertag: event.target.value })} />
            <TextField className="input" id="region" label="Region (optional)" value={values.region} onChange={event => setValues({ ...values, region: event.target.value })} />
            <Select
              className="input-banner"
              value={values.bannerType}
              inputProps={{
                name: 'selected-banner',
                id: 'selected-banner',
              }}
              onChange={(event) => setValues({...values, bannerType: event.target.value})}
            >
              {['Any Banner', 'Resistance', 'First Order'].map((item, i) => (
                <MenuItem key={`banner-menu-${i}`} value={item}>{item}</MenuItem>
              ))}
            </Select>
            <Button disabled={!values.gamertag} className="add-button" onClick={addReceiver}>Add {isPending && <i className="material-icons spinner">autorenew</i>}</Button>
          </FormControl>
        </div>
      </div>
    </Modal>
  );
}
