import React from 'react';

import Navigation, { UserContext } from '../components/Navigation';
import Account from '../views/Account';
import Home from '../views/Homepage';
import Map from '../views/Map';
import Missions from '../views/Missions';
import News from '../views/News';
import PublicWishlist from '../views/PublicWishlist';

export default function GlobalConsumer(globalProps) {
  return (
    <Navigation {...globalProps} >
      <UserContext.Consumer>
        {(props) => {
          return {
            account: <Account {...globalProps} {...props} />,
            home: <Home {...globalProps} {...props} />,
            map: <Map {...globalProps} {...props} />,
            missions: <Missions {...globalProps} {...props} />,
            news: <News {...globalProps} {...props} />,
            'public-wishlist': <PublicWishlist {...globalProps} {...props} />
          }[globalProps.section]
        }}
      </UserContext.Consumer>
    </Navigation>
  )
}