import React from 'react';

import { Button, Icon, Modal } from '@material-ui/core';

import './styles.scss';

export default function LoginPrompt(props) {
  const { onClose, openAccountCreation } = props;

  const handleClick = isCreateAccount => {
    window.analytics.track(`Clicked ${isCreateAccount ? 'create account' : 'log in'} from prompt`);

    onClose();
    openAccountCreation(isCreateAccount ? 0 : 1);
  }

  return (
    <Modal open={true} onClose={onClose}>
      <div className="login-prompt">
        <header>
          <h3>Log in or Create an Account</h3>
          <Icon className="close" onClick={onClose}>close</Icon>
        </header>

        <p>In order to save your wishlisted items, please create a free account.</p>

        <div className="buttons">
          <Button classes={{ root: 'create-account' }} onClick={() => handleClick()}>Log in</Button>
          <Button classes={{ root: 'create-account' }} onClick={() => handleClick(true)}>Create Account</Button>
        </div>
      </div>
    </Modal>
  )
}
