import React from 'react';

import classnames from 'classnames';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import AsyncButton from 'react-async-button';

import { MAX_UNPAID_WISHLIST_ITEMS } from '../../utils/constants';
import Spinner from '../Spinner';

import { Checkbox } from '@material-ui/core';

import { firebaseConfig } from '../../constants.js';

import './styles.scss';

const firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] :  firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

export default function Wishlist (props) {
  const { isOwn, isPaid, promptUpgrade, refreshUser, t, user, wishlist = [] } = props;
  const displayedWishlist = isPaid || wishlist.length <= MAX_UNPAID_WISHLIST_ITEMS ? wishlist : wishlist.slice(0, MAX_UNPAID_WISHLIST_ITEMS); 

  function removeFromWishlist(item) {
    const itemIndex = wishlist.findIndex(wItem => wItem.id === item.id);
    const isWishlisted = itemIndex >= 0;

    if (isWishlisted) wishlist.splice(itemIndex, 1);

    return db.collection('userPrefs').doc(user.id).set({
      hasWishlist: Boolean(wishlist.length),
      wishlist
    }, { merge: true }).then(() => {
      window.analytics.track('Removed item from wishlist', {
        category: 'wishlists',
        label: item.name,
      });

      refreshUser();
    }).catch(error => console.error(error));
  }

  return (
    <div className="wishlist-container">
      {Boolean(displayedWishlist.length)
        ? <div>
            {displayedWishlist.map(item => (
              <div key={item.id} className={`item-container ${item.rarity.toLowerCase()}`}>
                <div className={classnames('item-box', {
                  'center': item.category !== 'skins'
                })}>
                  <img className={classnames('item-image', {
                    emote: item.category === 'emotes',
                  })} src={item.imageUrl} alt={item.name} />
                </div>
                <div className="item-details">
                  <p>{item.name}</p>
                  
                  {isOwn && user?.id &&
                    <AsyncButton
                      className="wishlist-button"
                      onClick={() => removeFromWishlist(item)}
                      pendingText="Wishlist"
                      text="Wishlist"
                    >
                      {({buttonText, isPending}) => (
                        <>
                          {buttonText}

                          {isPending
                            ? <Spinner />
                            : <Checkbox
                              checked={true}
                              value={true}
                            />
                          }
                        </>
                      )}
                    </AsyncButton>
                  }
                </div>
              </div>
            ))}

            {isOwn && !isPaid && wishlist.length >= MAX_UNPAID_WISHLIST_ITEMS &&
              <div key="upgrade" className="item-container upgrade" onClick={() => promptUpgrade(false, 'from wishlist')}>
                <div className="item-details upgrade">
                  <p>{t('upgrade_your_account')}</p>
                  <p className="message">{t('set_unlimited_items')}</p>
                </div>
              </div>
            }
          </div>
        : <>
            <p>{t('click_all_items_tab')}</p>
            <p>{t('set_wishlist')}</p>
          </>
      }
    </div>
  )
}