import React, { useEffect, useState } from 'react';

import algoliasearch from 'algoliasearch';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import classnames from 'classnames';
import { debounce, get } from 'lodash';
import AsyncButton from 'react-async-button';
import { Waypoint } from 'react-waypoint';

import { ALGOLIA_API_KEY, ALGOLIA_APP_ID, firebaseConfig } from '../../constants.js';
import { translations } from '../../i18n.js';

import Spinner from '../../components/Spinner';

import { usePrevious } from '../../components/Navigation';

import { AppBar, Button, Checkbox, IconButton, InputBase, Modal, Tabs, Tab } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';

import './styles.scss';
import Wishlist from '../Wishlist/index.js';
import { MAX_UNPAID_WISHLIST_ITEMS } from '../../utils/constants.js';

const MIN_SEARCH_LENGTH = 3;

const algolia = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);
const index = algolia.initIndex('items');
const firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] :  firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

export default function WishlistBuilder(props) {
  const { breakpoint, isPaid, promptUpgrade, refreshUser, selectedTab, user } = props;

  const [browserItems, setBrowserItems] = useState([]);
  const [showWishlistBuilder] = useState(false);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterWishlist, setFilterWishlist] = useState(selectedTab !== 1);
  const [languageMap] = useState(translations[get(user, 'userPrefs.language', 'en')]);
  const [pendingWaypoint, setPendingWaypoint] = useState();

  const prevFilterSearch = usePrevious(filterSearch);
  const prevBrowserItems = usePrevious(browserItems);

  const isMobile = breakpoint === 'mobile';
  
  function t(key, names) {
    let str = languageMap[key];

    for (const name in names) {
      str = str.replace(`{{${name}}}`, names[name])
    }

    return str;
  }

  async function _loadNextPage(itemsLength) {
    if (!pendingWaypoint && showWishlistBuilder) {
      setPendingWaypoint(true);

      const bItems = await fetchItems(filterSearch, browserItems, itemsLength);

      setBrowserItems(bItems);
      setPendingWaypoint(false);
    }
  }

  function getFilterBar() {
    return (
      <div className="filter-bar">
        <div className="flexbar">
        {!isMobile && !filterWishlist &&
            <>
              <div className="search">
                <div className="search-icon">
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: "search-root",
                    input: "search-input",
                  }}
                  onChange={event => handleFilterChange(event, 'filterSearch')}
                  value={filterSearch}
                />
                <div onClick={() => setFilterSearch('')} className={classnames('cancel-icon', {
                  'show': filterSearch.length
                })}>
                  <CancelIcon />
                </div>
              </div>
            </>
          }
        </div>

        {isMobile && !filterWishlist &&
            <>
              <div className="search">
                <div className="search-icon">
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: "search-root",
                    input: "search-input",
                  }}
                  onChange={event => handleFilterChange(event, 'filterSearch')}
                  value={filterSearch}
                />
                <div onClick={() => setFilterSearch('')} className={classnames('cancel-icon', {
                  'show': filterSearch.length
                })}>
                  <CancelIcon />
                </div>
              </div>
            </>
          }
      </div>
    )
  }

  function handleFilterChange(event, type) {
    if (type === 'filterSearch') setFilterSearch(event.target.value);

    if (type === 'filterWishlist') {
      if (event) {
        setFilterSearch('');
        setFilterWishlist(true);
      } else {
        setFilterWishlist(false);
      }
    }
  }

  function getItemsTable() {
    const { user } = props;
    const wishlist = get(user, 'userPrefs.wishlist', []);
    const items = browserItems.filter(item => item.id);

    wishlist.sort((a, b) => a.name.localeCompare(b.name));

    const viewedItems = filterWishlist ? wishlist : items;
    const searchOk = !filterSearch.length || (filterSearch.length >= MIN_SEARCH_LENGTH);
    const tableMessage = searchOk ? 'No matching items found' : 'Enter at least three letters to get results';
    const emptyMessage = filterWishlist ? t('you_dont_have_items') : tableMessage;

    if (!viewedItems.length) return (
      <>
        <p>{emptyMessage}</p>

        {filterWishlist && 
          <Button onClick={() => setFilterWishlist(false)} className="view-all-items-button">{t('add_items_to_your_wishlist')}</Button>
        }
      </>
    )

    return (
      <ul className="items-table">
        {searchOk
          ? viewedItems.map(item => {
            const isWishlisted = wishlist.some(wItem => wItem.id === item.id);

            return (
              <li key={item.id} className={`item-container ${item.rarity.toLowerCase()}`}>
                <div className="item-box">
                  <img className={classnames('item-image', {
                    emote: item.category === 'emotes',
                  })} src={item.imageUrl} alt={item.name} />
                  {Boolean(item.price) &&
                    <p className="item-price">
                      <img src="/assets/icons/vBuck.png" alt="vBuck icon" /> {item.price}
                    </p>
                  }
                </div>
                <div className="item-details">
                  <p>{item.name}</p>
                  <p>{item.description}</p>

                  <AsyncButton
                    className="wishlist-button"
                    onClick={() => toggleWishlist(item)}
                    pendingText="Wishlist"
                    text="Wishlist"
                  >
                    {({buttonText, isPending}) => (
                      <>
                        {buttonText}

                        {isPending
                          ? <Spinner />
                          : <Checkbox
                            checked={isWishlisted}
                            value={isWishlisted}
                          />
                        }
                      </>
                    )}
                  </AsyncButton>
                </div>
              </li>
            )
          })
          : !pendingWaypoint && emptyMessage
        }
      </ul>
    );
  }

  function toggleWishlist(item) {
    const { isPaid, promptLogin, promptUpgrade, refreshUser, user } = props;

    if (!user || !user.id) return promptLogin();

    const wishlist = get(user, 'userPrefs.wishlist', []);
    const itemIndex = wishlist.findIndex(wItem => wItem.id === item.id);
    const isWishlisted = itemIndex >= 0;

    if (!isWishlisted && !isPaid && wishlist.length >= MAX_UNPAID_WISHLIST_ITEMS) return promptUpgrade(true, 'from wishlist builder');

    window.analytics.track(isWishlisted ? 'Removed item from wishlist' : 'Added item to wishlist', {
      category: 'wishlists',
      label: item.name,
    });

    if (isWishlisted) wishlist.splice(itemIndex, 1);
    else wishlist.push(item);

    return db.collection('userPrefs').doc(user.id).set({
      hasWishlist: Boolean(wishlist.length),
      wishlist
    }, { merge: true }).then(() => {
      refreshUser();
    }).catch(error => console.error(error));
  }

  function closeWishlistBuilder() {
    window.analytics.track('Closed wishlist builder');

    clearFilters();
    props.closeWishlistBuilder();
  }

  function clearFilters() {
    setFilterSearch('');
    setFilterWishlist('');
  }

  useEffect(() => {
    async function fetchData(searchTerm) {
      const bItems = await fetchItems(searchTerm);

      setBrowserItems(bItems);
      setPendingWaypoint(false);
    }

    const debouncedSearch = debounce(searchTerm => fetchData(searchTerm), 200);

    if (prevFilterSearch !== filterSearch && filterSearch.length >= MIN_SEARCH_LENGTH) {
      setPendingWaypoint(true);
      debouncedSearch(filterSearch);
    } else if (prevFilterSearch !== filterSearch && filterSearch.length > 1 && filterSearch.length < MIN_SEARCH_LENGTH) {
      setBrowserItems([]);
    } else if (prevFilterSearch !== filterSearch && !filterSearch.length) {
      setBrowserItems([]);
      fetchData();
    }
  }, [prevBrowserItems, prevFilterSearch, filterSearch, browserItems]);

  return (
    <Modal open={true} onClose={closeWishlistBuilder}>
      <div className="wishlist-builder">
        <AppBar className="user-bar" position="static">
          <Tabs value={filterWishlist ? 0 : 1} onChange={(event, newTab) => handleFilterChange(newTab === 0, 'filterWishlist')}>
            <Tab label={t('your_wishlist')} />
            <Tab label={t('all_items')} />

            <IconButton classes={{
              root: "close-wishlist-builder",
            }} onClick={closeWishlistBuilder}>
              <i className="material-icons">close</i>
            </IconButton>
          </Tabs>
        </AppBar>

        <div className="builder-content">
          {!filterWishlist && getFilterBar()}

          <div className="table-wrapper">
            {filterWishlist
              ? <Wishlist
                  isOwn={true} 
                  isPaid={isPaid} 
                  promptUpgrade={promptUpgrade}
                  refreshUser={refreshUser}
                  t={t}
                  user={user}
                  wishlist={user?.userPrefs?.wishlist}
                />
              : getItemsTable()
            }
          </div>

          {(!pendingWaypoint && Boolean(browserItems.length) && showWishlistBuilder && !filterWishlist && (!filterSearch.length || filterSearch.length >= MIN_SEARCH_LENGTH)) &&
            <Waypoint
              onEnter={() => _loadNextPage(browserItems.length)}
            />
          }

          {pendingWaypoint && <Spinner />}
        </div>
      </div>
    </Modal>
  );
}

async function fetchItems(filterSearch, browserItems = []) {
  const newItems = await index.search(filterSearch, { length: 20, offset: browserItems.length }).then(({ hits }) => {
    return hits;
  }).catch(err => console.error(`Failed to load items: ${err.message}`)) || [];

  return [...browserItems, ...newItems] || [];
}