import '@babel/polyfill';
import 'core-js';
import 'raf/polyfill';

import React, { useCallback, useEffect, useRef, useState } from "react";

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import classnames from 'classnames';
import { get } from 'lodash';
import * as queryString from 'query-string';
import { Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { firebaseConfig, stripeProdKey } from '../constants.js';
import { translations } from '../i18n.js';

import Banner from './Banner';
import CreateAccount from './CreateAccount';
import Donate from './Donate';
import DropdownMenu from './DropdownMenu';
import TeamUp from './TeamUp';
import UpgradeModal from './UpgradeModal';
import User from './User';

import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

const sections = ['home', 'missions', 'map', 'news', 'account'];

const firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] : firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

const stripePromise = loadStripe(stripeProdKey);

export const UserContext = React.createContext({
  breakpoint: '',
  completedChallenges: [],
  fetchCompletedChallenges: () => {},
  isBetaMember: false,
  isLoggedOut: true,
  isPaid: false,
  openAccountCreation: () => {},
  promptUpgrade: () => {},
  refreshUser: () => {},
  updateBetaMembership: () => {},
  user: {}
});

export default function Navigation(props) {
  const { children, history, location, match, section } = props;

  const currentSection = section || 'home';

  const [user, setUser] = useState({});
  const [completedChallenges, setCompletedChallenges] = useState([]);
  const [isLoggedOut, setIsLoggedOut] = useState();
  const [isBetaMember, setIsBetaMember] = useState();
  const [isPaid, setIsPaid] = useState();
  const [isWishlistPrompt, setIsWishlistPrompt] = useState(false);
  const [languageMap, setLanguageMap] = useState(translations.en);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showAccountCreationModal, setShowAccountCreationModal] = useState(false);
  const [values] = useState({ tab: 0 });
  const [bannerMsg, setBannerMsg] = useState();
  const [breakpoint, setBreakpoint] = useState(window.innerWidth >= 1200 ? 'desktop' : 'mobile');
  const [selectedTab, setSelectedTab] = useState();
  const [showSuccessBanner, setShowSuccessBanner] = useState();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [userCred, setUserCred] = useState();

  const prevUser = usePrevious(user);
  const prevLocation = usePrevious(location);

  // Used to open account modal to a specific tab, based on the route
  const getQueryData = useCallback(
    () => {
      const rawQueries = get(location, 'search', '?');
      const parsedQueries = queryString.parse(rawQueries);

      if (parsedQueries.modal && parsedQueries.modal === 'createaccount') openAccountCreation(0);
      else if (parsedQueries.modal && parsedQueries.modal === 'login') openAccountCreation(1);
      else if (parsedQueries.modal && parsedQueries.modal === 'settings') openAccountSettings(0);
      else if (parsedQueries.modal && parsedQueries.modal === 'badges') openAccountSettings(1);
    }, [location]
  );

  function checkBetaMembership() {
    if (!user.id) return setIsBetaMember(undefined);

    db.collection('beta-members').where('userId', '==', user.id).get().then(result => {
      if (!result.empty) {
        const { isActive } = result.docs[0].data();

        setIsBetaMember(isActive);
      } else setIsBetaMember(false);
    });
  }

  function checkPaidStatus() {
    if (!user.id) return setIsPaid(undefined);

    db.collection('paid-accounts').doc(user.id).get().then(result => {
      if (result.exists) {
        const { isActive, paymentExpiresOn } = result.data();
        const isExpired = +paymentExpiresOn < (Date.now() / 1000);

        setIsPaid(isActive && !isExpired);
      } else setIsPaid(false);
    });
  }

  useEffect(() => {
    if (location && location.search) getQueryData();

    firebase.auth().onAuthStateChanged(user => {
      if (user && user.email) {
        setIsLoggedOut(false);
        refreshUser();
      } else {
        setIsLoggedOut(true);
      }
    });

    window.addEventListener('resize', () => setBreakpoint);

    return window.removeEventListener('resize', () => setBreakpoint);
  }, [location, getQueryData]);

  useEffect(() => {
    if (location && prevLocation && location.search !== prevLocation.search) getQueryData();

    if (get(user, 'userPrefs.language') !== get(prevUser, 'userPrefs.language')) setLanguageMap(translations[get(user, 'userPrefs.language', 'en')]);

    if (user && !prevUser) window.analytics.identify(user.id, {
      name: user.displayName,
      email: user.email
    });
  }, [location, prevLocation, getQueryData, user, prevUser]);

  useEffect(() => {
    async function fetchData() {
      const newCC = await fetchCompletedChallenges(user);
      setCompletedChallenges(newCC);
    }

    fetchData();
  }, [user]);

  useEffect(() => {
    const callCheck = () => {
      if (!user.id) return setIsPaid(undefined);

      db.collection('paid-accounts').doc(user.id).get().then(result => {
        if (result.exists) {
          const { isActive, paymentExpiresOn } = result.data();
          const isExpired = +paymentExpiresOn < (Date.now() / 1000);

          setIsPaid(isActive && !isExpired);
        } else {
          setIsPaid(false);
        }
      });
    }

    if (user.id && isPaid === undefined) callCheck();
  }, [user.id, isPaid]);

  useEffect(() => {
    if (user.id && isBetaMember === undefined) {
      db.collection('beta-members').where('userId', '==', user.id).get().then(result => {
        setIsBetaMember(!result.empty);
      });
    }
  }, [user.id, isBetaMember]);

  useEffect(() => {
    if (showUpgradeModal && location.state?.openModalFor === 'edit-wishlist') setShowUpgradeModal(false);
  }, [location, showUpgradeModal]);

  const loginButton = user.id
    ? <Link to={'/account'} className="menu-item account-tab" >{t('account')}</Link>
    : <Button classes={{ label: 'create-account account-tab' }} onClick={() => openAccountCreation(1)}>Log in</Button>
  const showDonation = section === 'map';

  function t(key, names) {
    let str = languageMap[key];

    for (const name in names) {
      str = str.replace(`{{${name}}}`, names[name]);
    }

    return str;
  }

  function showSuccess(message) {
    setShowSuccessBanner(true);
    setBannerMsg(message);
  }

  function openAccountCreation (selectedTab) {
    setShowAccountCreationModal(true);
    setSelectedTab(selectedTab);
  }

  function openAccountSettings(selectedTab) {
    setShowAccountModal(true);
    setSelectedTab(selectedTab);
  }

  function promptUpgrade(isFromWishlist, action) {
    window.analytics.track(action, {
      category: 'upgrade prompt',
      label: user.id,
    });

    if (isFromWishlist) setIsWishlistPrompt(true);
    else setIsWishlistPrompt(false);

    setShowUpgradeModal(true);
  }

  function updateBetaMembership(event) {
    const isJoining = event.target.checked;

    if (!isPaid && isJoining) return promptUpgrade( false, 'Clicked join beta');

    db.collection('beta-members').doc(user.id).set({
      userId: user.id,
      isActive: isJoining,
    })
    .then (() => {
      window.analytics.track(isJoining ? 'Joined Beta program' : 'Left Beta program', {
        category: 'beta program',
        label: user.id,
      });

      refreshUser();
    });
  }

  function refreshUser() {
    const userCred = firebase.auth().currentUser;

    if (!userCred) {
      setUser({});
      setUserCred(undefined);
      setIsLoggedOut(true);
      setIsPaid(false);
      setIsBetaMember(false);

      return;
    };

    db.collection('userPrefs').doc(userCred.uid).get()
    .then(response => {
      if (!response.empty && response.exists) {
        const userPrefs = response.data();

        db.collectionGroup('badges').where('userId', '==', userCred.uid).get()
        .then(badgesRaw => {
          setUserCred(userCred);
          setUser({
            email: userCred.email,
            displayName: userCred.displayName,
            id: userCred.uid,
            userPrefs,
            badges: badgesRaw.docs.map(badge => badge.data())
          });
        })
        .catch();
      }
    });

    checkPaidStatus();
    checkBetaMembership();
  }

  function handleTabChange(value) {
    window.analytics.track('Switched navigation tabs', {
      label: sections[value]
    });
  }

  function closeModal() {
    window.analytics.track('Closed account modal',
      {
        category: 'account',
      }
    );

    setShowAccountModal(false);
    setShowAccountCreationModal(false);
    history.push({ search: undefined });
    refreshUser();
  }

  return (
    <UserContext.Provider value={{breakpoint, completedChallenges, fetchCompletedChallenges, isBetaMember, isLoggedOut, isPaid, openAccountCreation, promptUpgrade, setCompletedChallenges, updateBetaMembership, user, refreshUser}}>
      <div className={classnames('main-container', { 'has-donation': showDonation })}>
        <div className="navbar">
          <div className="navbar-content">
            {breakpoint === 'desktop'
              ? <Tabs className="navbar-tabs" value={sections.indexOf(currentSection)} onChange={(event, value) => handleTabChange(value)}>
                  <Tab label={t('home')} component={Link} to={'/home'}/>
                  <Tab label={t('news')} component={Link} to={'/news'}/>
                  {user?.id
                    ? <Tab label={t('account')} className="account-tab" component={Link} to={'/account'}/>
                    : <Tab label={'Log in'} className="account-tab" component={Button} onClick={() => openAccountCreation(1)}/>
                  }
                </Tabs>
              : <>
                  <TeamUp t={t} user={user} />
                  <DropdownMenu className="nav-dropdown" menuClass="nav-dropdown-menu" buttonIcon="menu" buttonClass="dropdown-button" items={[
                    <Link to={'/home'} className="menu-item" onClick={() => handleTabChange(0)}>{t('home')}</Link>,
                    <Link to={'/news'} className="menu-item" onClick={() => handleTabChange(3)}>{t('news')}</Link>,
                  ]} subItems={[ loginButton ]} />
                </>
            }

            {breakpoint === 'desktop' &&
              <TeamUp className="team-up-button" t={t} user={user} />
            }
          </div>
          <Banner className={classnames('success-banner', { 'open': showSuccessBanner })} onClose={() => setShowSuccessBanner(false)} show={true} dismissable={true}>
            {bannerMsg}
          </Banner>

          {showUpgradeModal &&
            <Elements stripe={stripePromise}>
              <UpgradeModal isWishlistPrompt={isWishlistPrompt} onClose={() => setShowUpgradeModal(false)} refreshUser={refreshUser} t={t} user={user} />
            </Elements>
          }
        </div>

        {children}

        {showDonation &&
          <div className="footer">
            <Donate breakpoint={breakpoint} />
          </div>
        }

        <CreateAccount open={showAccountCreationModal} selectedTab={selectedTab} closeModal={closeModal} history={history} match={match} location={location} transferCode={values.transferCode} />
        <User t={t} user={user} userCred={userCred} selectedTab={selectedTab} match={match} open={showAccountModal} history={history} closeModal={closeModal} refreshUser={refreshUser} section={section} showSuccess={showSuccess} />
      </div>
    </UserContext.Provider>
  );
}

function fetchCompletedChallenges(user) {
  if (!user.id) return;

  return db.collection('challengeCollections').doc(user.id).get()
  .then(response => {
    if (response.exists) {
      return db.collection('challengeCollections').doc(user.id).collection('completedChallenges').get()
      .then(completedChallengesRaw => {
        if (!completedChallengesRaw.empty) {
          const completedChallenges = completedChallengesRaw.docs.map(item => item.data());

          return completedChallenges;
        } else if (user.id) {
          return [];
        }
      })
    } else {
      initializeCollections(user)
    }
  }).catch(err => initializeCollections(user));
}

const initializeCollections = user => {
  if (!user.id) return;

  db.collection('challengeCollections').doc(user.id).set({
    userId: user.id
  }).catch();
}

export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
