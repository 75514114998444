import React, { Component } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/auth';

import { get } from 'lodash';

import { firebaseConfig } from '../../constants.js';
import { validateEmail } from '../../utils/regexes.js';

import Banner from '../Banner';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import './styles.scss';

const firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] :  firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

export default class CreateAccount extends Component {
  state = {
    emailPrefs: { features: false, weeklies: false },
    isValid: false,
    showAccountCreationModal: false,
    values: { email: '', tab: this.props.selectedTab || 1 }
  };

  componentDidMount() {
    window.analytics.page('window.location.pathname');
  }

  componentDidUpdate(prevProps) {
    const { selectedTab } = this.props;
    const { values } = this.state;

    if (selectedTab !== prevProps.selectedTab) this.setState({ values: { ...values, tab: selectedTab } });
  }

  render() {
    const { closeModal, open } = this.props;
    const { emailPrefs, errorMessage, isError, isPending, isValid, showPassword, values } = this.state;

    return (
      <Modal open={Boolean(open)} onClose={closeModal}>
        <div className="account-modal">
          <AppBar className="account-bar" position="static">
            <Tabs value={values.tab} onChange={this.handleTabChange}>
              <Tab label="Create Account" />
              <Tab label="Log In" />
              <IconButton onClick={closeModal} className="close-button" aria-label="close">
                <CloseIcon />
              </IconButton>
            </Tabs>
          </AppBar>

          <div className="inputs">
            <FormControl>
              <InputLabel htmlFor="adornment-email">Email</InputLabel>
              <Input
                autoComplete="off"
                id="adornment-email"
                value={values.email}
                onChange={event => this.handleChange(event, 'email')}
              />
            </FormControl>

            <FormControl>
             <InputLabel htmlFor="adornment-password">Password</InputLabel>
             <Input
               autoComplete="off"
               id="adornment-password"
               type={showPassword ? 'text' : 'password'}
               value={values.password}
               onChange={event => this.handleChange(event, 'password')}
               endAdornment={
                 <InputAdornment position="end">
                   <IconButton aria-label="Toggle password visibility" onClick={this.toggleShowPassword}>
                     {showPassword ? <Visibility /> : <VisibilityOff />}
                   </IconButton>
                 </InputAdornment>
               }
             />
           </FormControl>

           {values.tab === 0 &&
             <>
              <FormControl className="email-prefs" component="fieldset">
                <FormLabel component="legend"><h4>Receive Emails About:</h4></FormLabel>
                  <FormGroup>
                    <FormControlLabel
                     control={<Checkbox checked={emailPrefs.features} onChange={event => this.handlePrefsChange(event, 'features')} value="features" />}
                     label="New / updated features (usually monthly)"
                   />
                 </FormGroup>
               </FormControl>
             </>
            }

           <Banner show={isError} type="error" >{errorMessage}</Banner>

           {values.tab === 0 && <Button onClick={this.submitNewAccount} disabled={!isValid}>Submit {isPending && <i className="material-icons spinner">autorenew</i>}</Button>}
           {values.tab === 1 && <Button onClick={this.logIn} disabled={!isValid}>Log In {isPending && <i className="material-icons spinner">autorenew</i>}</Button>}
          </div>
        </div>
      </Modal>
    );
  }

  closeModal = () => {
    const { closeModal } = this.props;

    this.setState({ isPending: false, isError: false, isValid: false, showAccountCreationModal: false, showPassword: false, values: { email: '', tab: 1 } }, () => {
      closeModal();
    });
  }

  handleTabChange = (event, value) => {
    const { match } = this.props;
    const { values } = this.state;

    values.tab = value;
    this.setState({ isError: false, isPending: false, values }, () => {
      this.props.history.push({ pathname: `/${get(match.params, 'section', 'home')}`, search: values.tab === 0 ? 'modal=createaccount' : 'modal=login' });
    });
  }

  handlePrefsChange = (event, preference) => {
    const { emailPrefs } = this.state;

    emailPrefs[preference] = event.target.checked;

    this.setState({ emailPrefs });
  }

  logIn = () => {
    const { values: { email, password } } = this.state;

    this.setState({ isPending: true });

    firebase.auth().signInWithEmailAndPassword(email, password)
    .then(() => {
      this.closeModal();
    })
    .catch(error => {
      this.setState({ isError: true, isPending: false, errorMessage: error.message }); // TODO: log error.code
    });
  }

  submitNewAccount = () => {
    const { emailPrefs, values: { email, password } } = this.state;

    this.setState({ isPending: true });

    firebase.auth().createUserWithEmailAndPassword(email, password)
    .then(response => {
      const { uid } = response.user;

      db.collection('userPrefs').doc(uid).set({ emailPrefs , userId: uid })
      .then(() => {
        this.closeModal();
      })
      .catch(error => this.setState({ isError: true, isPending: false, errorMessage: error.message })); // TODO: log error.code
    })
    .catch(error => {
      this.setState({ isError: true, isPending: false, errorMessage: error.message }); // TODO: log error.code
    });
  }

  toggleShowPassword = () => {
    const { showPassword } = this.state;

    this.setState({ showPassword: !showPassword });
  }

  handleChange = (event, target) => {
    const { values } = this.state;

    values[target] = event.target.value;

    const isValid = validateEmail(values.email) && values.password && values.password.length >= 7;

    this.setState({ isValid, values, isError: false });
  }
}
