import React, { useEffect, useRef, useState } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { get } from 'lodash';

import { firebaseConfig } from '../../constants.js';
import { translations } from '../../i18n.js';

import Spinner from '../../components/Spinner';
import Wishlist from '../../components/Wishlist';

import './styles.scss';

const firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] : firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

export default function PublicWishlist(props) {
  const { isPaid: isCurrentUserPaid, match: { params: { wishlistId } }, promptUpgrade, user } = props;

  const listRef = useRef(null);

  const [isLoadingWishlist, setIsLoadingWishlist] = useState(true);
  const [isPaid, setIsPaid] = useState();
  const [languageMap, setLanguageMap] = useState(translations[get(user, 'userPrefs.language', 'en')]);
  const [publicUser, setPublicUser] = useState();
  const [wishlist, setWishlist] = useState();

  function t(key, names) {
    let str = languageMap[key];

    for (const name in names) {
      str = str.replace(`{{${name}}}`, names[name])
    }

    return str;
  }

  function getEmptyWishlist() {
    return (
      <p>
        {t('no_items_in_wishlist', { name: publicUser?.displayName || t('this_user') })}
      </p>
    );
  }

  useEffect(() => {
    if (publicUser && isPaid === undefined) {
      db.collection('paid-accounts').where('userId', '==', publicUser.userId).get().then(result => {
        if (result.exists) {
          const { isActive, paymentExpiresOn } = result.data();
          const isExpired = +paymentExpiresOn < (Date.now() / 1000);
  
          setIsPaid(isActive && !isExpired);
        } else setIsPaid(false);
      });
    }
  }, [publicUser, isPaid]);

  useEffect(() => {
    function getPublicUser() {
      db.collection('public-wishlists').doc(wishlistId).get()
      .then(result => {
        if (result) {
          const data = result.data();
          
          db.collection('userPrefs').doc(data.userId).get()
          .then(publicUserData => setPublicUser(publicUserData.data()));
        }
      });
    }

    if (!publicUser && wishlistId) getPublicUser();
  }, [publicUser, wishlistId]);

  useEffect(() => {
    function fetchWishlist() {
      setIsLoadingWishlist(true);

      db.collection('public-wishlists').doc(wishlistId).get()
      .then(rawWishlist => {
        if (rawWishlist) {
          setWishlist(rawWishlist.data());
          setIsLoadingWishlist(false);
        }
      })
    }

    window.analytics.page(`wishlist ${wishlistId}`);
    if (wishlistId && !wishlist) fetchWishlist();
  }, [wishlistId, wishlist]);

  useEffect(() => {
    setLanguageMap(translations[get(user, 'userPrefs.language', 'en')]);
  }, [user]);

  useEffect(() => {
    if (publicUser?.userId === user.id) {
      setIsPaid(isCurrentUserPaid);
    }
  }, [isCurrentUserPaid, publicUser, user]);

  return (
    <div ref={listRef} className="public-wishlist">
      {isLoadingWishlist
        ? <Spinner />
        : <>
            {publicUser
              ? <>
                  {publicUser.wishlist?.length
                    ? <>
                        <div className="header">
                          <h2>
                            {publicUser.displayName
                              ? t('users_wishlist', { name: publicUser.displayName })
                              : t('wishlist')
                            }
                          </h2>
                        </div>

                        {publicUser.userId !== user?.id
                          ? <>
                              <p className="message">
                                {`${t('youre_viewing_wishlist')} ${user.id ? t('edit_your_own') : t('login_or_create_to_make')}`}
                              </p>
                            </>
                          : <p className="message">{t('this_is_your_wishlist')}</p>
                        }

                        <Wishlist isOwn={publicUser.userId === user.id} isPaid={isPaid} promptUpgrade={promptUpgrade} wishlist={publicUser.wishlist} t={t} />
                      </>
                    : getEmptyWishlist()
                  }
                </>
              : <p>{t('wishlist_not_found')}</p>
            }
          </>
      }
    </div>
  );
}
