import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import MediaCard from '../../../../components/MediaCard';
import Spinner from '../../../../components/Spinner';

import './styles.scss';

export default class Feature extends Component {
  render () {
    const { buttonText, description, imgSrc, isLoading, label, title, url } = this.props;

    return (
      <section>
        {!isLoading && <h3>{label}</h3>}

        {isLoading
          ? <Spinner />
          : <MediaCard
              cardClass="card"
              imageClass="card-image"
              imageSrc={imgSrc}
              content={description}
              cardActions={
                <Button size="small" className="open-in-map">
                  {buttonText}
                </Button>
              }
              onClick={this.handleClick}
              imageAlt={`Screenshot`}
              title={title}
              url={url}
            />
        }
      </section>
    );
  }

  handleClick = (event) => {
    const { history, isExternal, title, url } = this.props;

    event.stopPropagation();

    if (isExternal) window.open(url, '_blank');
    else history.push(url);

    window.analytics.track('Clicked a feature card', {
      label: title
    });
  }
}
