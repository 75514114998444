import React, { Component } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import classnames from 'classnames';
import { get } from 'lodash';

import { firebaseConfig } from '../../constants.js';
import { validateEmail } from '../../utils/regexes.js';

import Banner from '../Banner';
import Badge from '../Badge';
import Spinner from '../Spinner';

import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import './styles.scss';

const firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] :  firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

class UserComponent extends Component {
  state = {
    emailPrefs: get(this.props, 'user.userPrefs.emailPrefs', { features: false, weeklies: false }),
    updatedValues: {
      currentPassword: '',
      newPassword: ''
    },
    tab: this.props.selectedTab
  };

  componentDidMount() {
    this.fetchLanguages();

    window.analytics.page(window.location.pathname);
  }

  componentDidUpdate(prevProps) {
    const { selectedTab, user } = this.props;
    const currentEmailPrefs = get(user, 'userPrefs.emailPrefs');

    if (currentEmailPrefs !== get(prevProps, 'user.userPrefs.emailPrefs')) this.setState({ emailPrefs: currentEmailPrefs || { features: false, weeklies: false } });
    if (selectedTab !== prevProps.selectedTab) this.setState({ tab: selectedTab });
  }

  render() {
    const { open, user = {}, t } = this.props;
    const {
      editing, emailEdited, emailPrefs, isPending, isValid, languageLoading, languages, logoutPending, showPassword,
      success, updatedValues, tab = 0
    } = this.state;
    const { email = '' } = user;
    const selectedLanguage = get(user, 'userPrefs.language', 'en');
    // TODO: add resend code button for unverified email

    return (
      <Modal open={Boolean(open)} onClose={this.closeModal}>
        <div className="user-modal">
          <AppBar className="user-bar" position="static">
            <Tabs value={tab} onChange={this.handleTabChange}>
              <Tab label={t('user-settings')} />
              <Tab label={t('user-badges')} />
            </Tabs>
            <IconButton className="close" onClick={this.closeModal}>
              <i className="material-icons">close</i>
            </IconButton>
          </AppBar>

          {tab === 0 &&
            <div className="user-info">
              <div className="header">
                <h2>{t('user-account_settings')}</h2>
                <Button className="log-out" onClick={this.logOut}>{t('user-log_out')} {logoutPending && <i className="material-icons spinner">autorenew</i>}</Button>
              </div>

              <div className="account-settings">
                {editing === 'email'
                  ? <FormControl className="email-input">
                      <InputLabel htmlFor="adornment-email">{t('user-email')}</InputLabel>
                      <Input
                        id="adornment-email"
                        value={emailEdited ? updatedValues.email : email}
                        onChange={event => this.handleChange(event, 'email')}
                      />
                    </FormControl>
                  : email
                }

                {editing
                  ? <div>
                      <FormControl className="cur-pw-input">
                        <InputLabel htmlFor="adornment-password">{t('user-current_password')}</InputLabel>
                        <Input
                          autoComplete="off"
                          id="adornment-password"
                          type="password"
                          value={updatedValues.currentPassword}
                          onChange={event => this.handleChange(event, 'currentPassword')}
                        />
                      </FormControl>
                      {editing === 'password' &&
                        <FormControl className="new-pw-input">
                          <InputLabel htmlFor="adornment-password">{t('user-new_password')}</InputLabel>
                          <Input
                            id="adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={updatedValues.newPassword}
                            onChange={event => this.handleChange(event, 'newPassword')}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton aria-label="Toggle new password visibility" onClick={this.toggleShowPassword}>
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      }
                    </div>
                  : <div className="edit-buttons">
                      <Button className="edit-email" onClick={this.toggleEditEmail} aria-label="Edit">
                        {t('user-edit_email')}
                      </Button>
                      <Button className="update-password" onClick={() => this.setState({ editing: 'password' })}>
                        {t('user-change_password')}
                      </Button>
                    </div>
                }
              </div>

              {editing &&
                <div className="account-buttons">
                  <Button onClick={this.cancelEdit} aria-label="Cancel">
                    {t('user-cancel')}
                  </Button>
                  <Button disabled={!isValid} onClick={this.saveAccountSettings} aria-label="Save">
                    {t('user-save')} {isPending && <i className="material-icons spinner">autorenew</i>}
                  </Button>
                </div>
              }

              <div className="language-select">
                {t('user-dashboard_language')}
                {languages && languages.map(language => (
                  <div
                    key={language.id}
                    onClick={() => this.setLanguage(language.id)}
                    className={classnames('language', { 'selected': selectedLanguage === language.id })}
                  >
                    <img alt={`Icon for ${language.name}`} src={language.icon_url} />
                    <span>{language.name}</span>
                    {languageLoading === language.id && <Spinner />}
                  </div>
                ))}
              </div>

              <FormControl component="fieldset">
                <FormLabel component="legend"><h4>{t('user-receive_emails_about')}</h4></FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={emailPrefs.features} onChange={event => this.handlePrefsChange(event, 'features')} value="features" />}
                    label={t('user-new_features')}
                  />
                </FormGroup>
              </FormControl>
            </div>
          }

          {tab === 1 &&
            <div className="badges">
              <div className="header">
                <h2>{t('user-profile_badges')}</h2>
                <Button className="log-out" onClick={this.logOut}>{t('user-log_out')} {logoutPending && <i className="material-icons spinner">autorenew</i>}</Button>
              </div>

              {(!user.badges) && <Spinner />}
              {user.badges && user.badges.map((badge, i) => <Badge key={`${badge.badgeKey}-${i}`} { ...badge } avatarClass="avatar" />)}
            </div>
          }

          <Banner show={success} type="success">{t('user-update_success', { success })}</Banner>
        </div>
      </Modal>
    );
  }

  handleTabChange = (event, tab) => {
    const { match } = this.props;

    event.stopPropagation();

    this.setState({ tab }, () => {
      this.props.history.push({ pathname: get(match.params, 'section', 'home'), search: tab === 0 ? 'modal=settings' : 'modal=badges' });
    });
  }

  logOut = () => {
    const { refreshUser } = this.props;

    this.setState({ logoutPending: true });
    firebase.auth().signOut().then(() => {
      this.closeModal();
      refreshUser();
      this.props.history.push({ pathname: 'home' });
    }, function(error) {
      // An error happened.
    });
  }

  cancelEdit = () => {
    this.setState({ updatedValues: { currentPassword: '', newPassword: '' }, editing: undefined, emailEdited: false, isError: false, isPending: false, isValid: false });
  }

  saveAccountSettings = () => {
    const { refreshUser } = this.props;
    const { editing, isValid, updatedValues } = this.state;

    if (!isValid) return;

    if (editing === 'password' && updatedValues.newPassword.length) {
      this.setState({ isPending: true });

      this.logIn(() => {
        const userCred = firebase.auth().currentUser;

        userCred.updatePassword(updatedValues.newPassword).then(() => {
          this.setState({ success: 'password' }, () => {
            this.cancelEdit();
            refreshUser();
          });
        }).catch(error => {
          this.setState({ isError: true, isPending: false });
        });
      })
    } else if (editing === 'email' && updatedValues.email.length) {
      this.setState({ isPending: true });

      this.logIn(() => {
        const userCred = firebase.auth().currentUser;

        userCred.updateEmail(updatedValues.email).then(() => {
          this.setState({ success: 'email' }, () => {
            this.cancelEdit();
            refreshUser();
          });
        }).catch(error => {
          this.setState({ isError: true, isPending: false });
        });
      })
    }
  }

  closeModal = () => {
    const { closeModal } = this.props;

    this.setState({ success: undefined, logoutPending: false }, () => {
      this.cancelEdit();
      closeModal();
    });
  }

  toggleEditEmail = () => {
    const { editing } = this.state;

    this.setState({ editing: editing !== 'email' && 'email' });
  }

  logIn = (callback) => {
    const { user } = this.props;
    const { updatedValues } = this.state;

    firebase.auth().signInWithEmailAndPassword(user.email, updatedValues.currentPassword)
    .then(() => callback())
    .catch(function(error) {
      this.setState({ isError: true, errorMessage: error.message }); // TODO: log error.code
    });
  }

  toggleShowPassword = () => {
    const { showPassword } = this.state;

    this.setState({ showPassword: !showPassword });
  }

  handlePrefsChange = (event, preference) => {
    const { refreshUser, t } = this.props;
    const { emailPrefs } = this.state;
    const user = firebase.auth().currentUser;

    if (!user || !user.uid) return;

    this.cancelEdit();
    this.setState({ success: undefined });
    emailPrefs[preference] = event.target.checked;

    this.setState({ emailPrefs });

    db.collection('userPrefs').doc(user.uid).update({ emailPrefs , userId: user.uid })
    .then((response) => {
      this.setState({ success: t('user-email_preference') }, () => {
        refreshUser();
      });
    })
    .catch();
  }

  handleChange = (event, target) => {
    const { user = {} } = this.props;
    const { editing, updatedValues } = this.state;
    let isValid = true;

    updatedValues[target] = event.target.value;

    if (editing === 'email') isValid = Boolean(validateEmail(updatedValues.email) && updatedValues.email !== user.email && updatedValues.currentPassword.length);
    else if (editing === 'password') isValid = updatedValues.newPassword && updatedValues.newPassword.length >= 7;

    this.setState({ isValid, updatedValues, emailEdited: editing === 'email' });
  }

  setLanguage = language => {
    const { refreshUser, user } = this.props;

    if (!user.id) return;

    this.setState({ languageLoading: language });

    db.collection('userPrefs').doc(user.id).update({ language })
    .then((response) => {
      this.setState({ languageLoading: '' }, () => {
        refreshUser();
      });
    })
    .catch();
  }

  fetchLanguages = () => {
    db.collection('languages').get().then(rawLanguages => {
      const languages = rawLanguages.docs.map(rl => rl.data());

      this.setState({ languages });
    });
  }
}

export default class User extends Component {
  render () {
    return (
      <UserComponent {...this.props} />
    )
  }
}