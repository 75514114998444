import React, { useEffect, useRef, useState } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { get } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { firebaseConfig } from '../../constants.js';
import { translations } from '../../i18n.js';

import { Button } from '@material-ui/core';

import './styles.scss';

const firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] : firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

export default function News(props) {
  const newsRef = useRef(null);
  const [articles, setArticles] = useState([]);
  const [articleId, setArticleId] = useState(get(props.match.params, 'articleId', ''));
  const [languageMap, setLanguageMap] = useState(translations[get(props.user, 'userPrefs.language', 'en')]);
  const [noArticles, setNoArticles] = useState(false);

  function t(key, names) {
    let str = languageMap[key];

    for (const name in names) {
      str = str.replace(`{{${name}}}`, names[name])
    }

    return str;
  }

  function getArticles() {
    return (
      <div className="articles">
        {noArticles
          ? 'No articles found. Please try again later.'
          : articles.length
            ? articles.map((item) => (
                <div className="article" key={item.id} onClick={() => selectArticle(item.id)}>
                  <img src={item.thumbnail_image} alt="thumbnail" />

                  <div>
                    <h2>{item.title}</h2>
                    <p className="subtitle">{item.subTitle}</p>
                  </div>
                </div>
              ))
            : 'Loading...'
        }
        <p className="contribute">
          {t('news_blurb')} <a href="mailto:newsfeed@fortnitedashboard.com">newsfeed@fortnitedashboard.com</a>.
        </p>
      </div>
    );
  }

  function getArticle() {
    const openArticle = articles.find(item => item.id === articleId);

    if (!openArticle) return;

    return (
      <div className="article-container">
        <div>
          <i onClick={() => selectArticle('')} className="back material-icons">arrow_back</i>

          <img className="cover" src={openArticle.cover_image} alt="article cover" />

          {openArticle.challengesheet &&
            <Link to={`/missions/${openArticle.challengesheet}`}>
              <Button className="view-challenges">{t('view_challenge_sheet')}</Button>
            </Link>
          }
          <h1>{openArticle.title}</h1>
        </div>
        <div className="content">
          <p className="subtitle">{openArticle.subTitle}</p>

          {openArticle.content.map((item, i) => {
            if (item.type === 'paragraph') {
              if (!item.content.includes('[')) {
                return (
                  <p key={`paragraph-${i}`} className={item.style}>{item.content}</p>
                );
              }

              const paragraphArr = item.content.split(' ');

              paragraphArr.forEach((item, i) => {
                if (item.includes('[link:')) {
                  const lastIndex = item.lastIndexOf(':');
                  const url = item.substring(item.indexOf(':') + 1, lastIndex);
                  const display = item.substring(lastIndex + 1, item.length - 1);
                  const newItem = (
                    `<a href="${url}">${display.split('-').join(' ')}</a>`
                  );

                  paragraphArr.splice(i, 1, newItem);
                }
              });

              const parsedContent = paragraphArr.join(' ');

              return (
                <p key={`paragraph-${i}`} className={item.style} dangerouslySetInnerHTML={{ __html: parsedContent }} />
              )
            } else {
              const newElement = React.createElement(item.element, item.attrs, item.content);

              return <React.Fragment key={`element-${i}`}>{newElement}</React.Fragment>;
            }
          })}
        </div>
        <p className="byline">
          {t('posted', { date: moment.unix(Date.parse(openArticle.timeStamp.toDate()) / 1000).format('LLL') })} {t('by', { author: openArticle.author })}
        </p>

        <section className="about-author">
          <h3>About the Author</h3>

          <p><strong>CaliforniaQT</strong> is the in-game-name for Natalie Beaumont (me). I run FortniteDashboard.com on my own, automating as much as I can in order to provide valuable content without invasive advertisements.</p>
          <p>You can always reach me at <a href="mailto:natalie@fortnitedashboard.com">natalie@fortnitedashboard.com</a>.</p>
        </section>
      </div>
    );
  }

  function selectArticle(selectedId) {
    setArticleId(selectedId);
    props.history.push(`/news/${selectedId}`);
  }

  function fetchArticles() {
    db.collection('articles').where('publish', '==', true).orderBy('timeStamp', 'desc').get()
    .then(rawArticles => {
      if (rawArticles && !rawArticles.empty) {
        const articles = rawArticles.docs.map(article => ({ ...article.data(), id: article.id }));

        setArticles(articles)
      } else setNoArticles(true);
    })
  }

  useEffect(() => {
    fetchArticles();
    window.analytics.page('news');
  }, []);

  useEffect(() => {
    newsRef && newsRef.current.scrollTo && newsRef.current.scrollTo(0, 0);
  }, [articleId]);

  useEffect(() => {
    setLanguageMap(translations[get(props.user, 'userPrefs.language', 'en')]);
  }, [props.user]);

  return (
    <div ref={newsRef} className="news">
      {articleId.length
        ? getArticle()
        : getArticles()
      }
    </div>
  );
}
