export const projectID = 'fortnite-dashboard-b545a';

export const trackingIdGA = 'UA-143062734-1';

export const firebaseConfig = {
  apiKey: 'AIzaSyCWfU3eDgB2K1O4TSDXv_HP-Z0WoxW4nkY',
  authDomain: 'fortnite-dashboard-b545a.firebaseapp.com',
  databaseURL: 'https://fortnite-dashboard-b545a.firebaseio.com',
  projectId: 'fortnite-dashboard-b545a',
  storageBucket: 'fortnite-dashboard-b545a.appspot.com',
  messagingSenderId: '743744717344',
  appId: '1:743744717344:web:58ef24460740f03d'
}

export const firebaseConfig2 = {
  apiKey: "AIzaSyAP8GddB94rygh-F00NjPDsY8szvK3as60",
  authDomain: "fortbyte-map.firebaseapp.com",
  databaseURL: "https://fortbyte-map.firebaseio.com",
  projectId: "fortbyte-map",
  storageBucket: "fortbyte-map.appspot.com",
  messagingSenderId: "934533385222",
  appId: "1:934533385222:web:fbfc0bf1f01b1ad6"
};

export const ALGOLIA_APP_ID = 'Z8TK6Z4XE0';
export const ALGOLIA_API_KEY = '9a8d8e908150b000376b7e8faf429ca4';

export const stripeSandboxKey = 'pk_test_pTsJsmsGXLig3DqXoWHpEmJd00amLYrog5';
export const stripeProdKey = 'pk_live_ma53cnFavhCyovlzAZTxoKwI0098iM9edT';